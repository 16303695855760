// The dropdown wrapper (`<div>`)
.dropup,
.dropright,
.dropdown,
.dropleft {
    position: relative;
    &.bento-menu {
        .btn::after {
            display: none;
        }
        .dropdown-header {
            color: $blue-zodiac-light;
            font-size: 1rem;
            padding-bottom: 1.3rem;
            min-width: 18.75rem;
        }
        .bento-menu-items-wrapper {
            border-radius: 0 0 12px 12px;
            display: flex;
            flex-direction: column;
            .bento-menu-item {
                display: flex;
                flex-direction: row;
                color: $blue-zodiac-light;
                font-weight: 400;
                font-size: 1rem;
                padding: 0.45rem 0.75rem;
                border-radius: 6px;
                justify-content: flex-start;
                align-items: center;
                .icon {
                    margin-right: 0.3rem;
                }
                &:hover,
                &:focus {
                    background-color: $gray-300;
                    text-decoration: none;
                }
            }
        }
    }
}

.dropdown-toggle {
    white-space: nowrap;
    position: relative;

    // Generate the caret automatically
    @include caret();

    &.btn-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        &::after {
            display: block !important;
        }
    }
}

// The dropdown menu
.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: $zindex-dropdown;
    display: none; // none by default, but block on "open" of the menu
    float: left;
    min-width: $dropdown-min-width;
    padding: $dropdown-padding-y $dropdown-padding-x;
    margin: $dropdown-spacer 0 0; // override default ul
    @include font-size($dropdown-font-size);
    color: $dropdown-color;
    text-align: left; // Ensures proper alignment if parent has it changed (e.g., modal footer)
    list-style: none;
    background-color: $dropdown-bg;
    background-clip: padding-box;
    border: $dropdown-border-width solid $dropdown-border-color;
    @include border-radius($dropdown-border-radius);
    // @include box-shadow($dropdown-box-shadow);
    box-shadow: $box-shadow-lg;

    section {
        padding: $dropdown-item-padding-y $dropdown-item-padding-x;
        h5 {
            font-size: 0.938rem;
        }
    }
    &.responsive-height {
        max-height: 85vh;
        overflow: auto;
    }
}

@each $breakpoint in map-keys($grid-breakpoints) {
    @include media-breakpoint-up($breakpoint) {
        $infix: breakpoint-infix($breakpoint, $grid-breakpoints);

        .dropdown-menu#{$infix}-left {
            right: auto;
            left: 0;
        }

        .dropdown-menu#{$infix}-right {
            right: 0;
            left: auto;
        }
    }
}

// Allow for dropdowns to go bottom up (aka, dropup-menu)
// Just add .dropup after the standard .dropdown class and you're set.
.dropup {
    .dropdown-menu {
        top: auto;
        bottom: 100%;
        margin-top: 0;
        margin-bottom: $dropdown-spacer;
    }

    .dropdown-toggle {
        @include caret(up);
    }
}

.dropright {
    .dropdown-menu {
        top: 0;
        right: auto;
        left: 100%;
        margin-top: 0;
        margin-left: $dropdown-spacer;
    }

    .dropdown-toggle {
        @include caret(right);
        &::after {
            vertical-align: 0;
        }
    }
}

.dropleft {
    .dropdown-menu {
        top: 0;
        right: 100%;
        left: auto;
        margin-top: 0;
        margin-right: $dropdown-spacer;
    }

    .dropdown-toggle {
        @include caret(left);
        &::before {
            vertical-align: 0;
        }
    }
}

// When enabled Popper.js, reset basic dropdown position
// stylelint-disable-next-line no-duplicate-selectors
.dropdown-menu {
    &[x-placement^='top'],
    &[x-placement^='right'],
    &[x-placement^='bottom'],
    &[x-placement^='left'] {
        right: auto;
        bottom: auto;
    }
}

// Dividers (basically an `<hr>`) within the dropdown
.dropdown-divider {
    @include nav-divider($dropdown-divider-bg, $dropdown-divider-margin-y, true);
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    display: block;
    width: 100%; // For `<button>`s
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    clear: both;
    font-weight: $font-weight-normal;
    color: $dropdown-link-color;
    text-align: inherit; // For `<button>`s
    text-decoration: if($link-decoration == none, null, none);
    white-space: nowrap; // prevent links from randomly breaking onto new lines
    background-color: transparent; // For `<button>`s
    border: 0; // For `<button>`s

    // Prevent dropdown overflow if there's no padding
    // See https://github.com/twbs/bootstrap/pull/27703
    @if $dropdown-padding-y == 0 {
        &:first-child {
            @include border-top-radius($dropdown-inner-border-radius);
        }

        &:last-child {
            @include border-bottom-radius($dropdown-inner-border-radius);
        }
    }

    @include hover-focus() {
        color: $dropdown-link-hover-color;
        text-decoration: none;
        @include gradient-bg($dropdown-link-hover-bg);
    }
    .checkmark {
        display: none !important;
    }
    &.active,
    &:active {
        color: $dropdown-link-active-color;
        text-decoration: none;
        font-weight: 500;
        // @include gradient-bg($dropdown-link-active-bg);
        .checkmark {
            display: block !important;
        }
        &::after {
            content: '';
            background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='14' height='11' viewBox='0 0 14 11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.75502 10.2349L0.205017 5.68493C-0.0683389 5.41157 -0.0683389 4.96836 0.205017 4.69497L1.19494 3.70502C1.4683 3.43164 1.91154 3.43164 2.1849 3.70502L5.25 6.77009L11.8151 0.205017C12.0885 -0.0683389 12.5317 -0.0683389 12.8051 0.205017L13.795 1.19497C14.0683 1.46833 14.0683 1.91154 13.795 2.18492L5.74498 10.235C5.47159 10.5083 5.02838 10.5083 4.75502 10.2349V10.2349Z' fill='%230058BC'/%3E%3C/svg%3E");
            background-repeat: no-repeat;
            background-position: center center;
            display: block;
            width: 1.1rem;
        }
    }

    &.disabled,
    &:disabled {
        color: $dropdown-link-disabled-color;
        pointer-events: none;
        background-color: transparent;
        // Remove CSS gradients if they're enabled
        @if $enable-gradients {
            background-image: none;
        }
    }
    &.lead-icon {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 1rem;
        .icon {
            text-align: center;
            min-width: 2rem;
            margin-right: 0.8rem;
        }
        &.user-email {
            pointer-events: none;

            .icon {
                svg {
                    display: block;
                    width: 100%;
                    height: auto;
                }
            }
        }
    }
    &.danger {
        color: $red;

        @include hover-focus() {
            background-color: $red-light;
        }
    }
    svg {
        display: block;
        min-width: 1.4rem;
    }
}

.dropdown-menu.show {
    display: block;
}

// Dropdown section headers
.dropdown-header {
    display: block;
    padding: $dropdown-header-padding;
    margin-bottom: 0; // for use with heading elements
    @include font-size($font-size-sm);
    color: $dropdown-header-color;
    white-space: nowrap; // as with > li > a
}

// Dropdown text
.dropdown-item-text {
    display: block;
    padding: $dropdown-item-padding-y $dropdown-item-padding-x;
    color: $dropdown-link-color;
}

.dropdown-heading {
    border-bottom: 1px solid $gray-500;
    .name {
        p {
            font-size: 0.875rem;
            font-weight: 500;
            color: $lii-text;
        }
    }
}

.dropdown {
    .profile-name {
        svg {
            max-width: 1.5rem !important;
            height: auto;
        }
    }
}


// Custom width for specific dropowns
@include media-breakpoint-up(sm) {
    .edit-subscription {
        min-width: 23.75rem;
    }
}

#DateRangePicker {
    min-width: 16rem;
}
