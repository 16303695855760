.filter-bar {
    display: block;
    padding: 0;
    z-index: 2;
    .filter-and-search {
        .filter-pills {
            // white-space: nowrap;
            width: 100%;
            overflow: auto;
            flex-wrap: nowrap;
            .custom-radio {
                flex-shrink: 0;
                display: inline-block;
            }
            &::-webkit-scrollbar-track
            {
                -webkit-box-shadow: none;
                border-radius: 0;
                background-color: transparent;
            }
            
            &::-webkit-scrollbar
            {
                height: 2px;
                background-color: transparent;
            }
            
            &::-webkit-scrollbar-thumb
            {
                border-radius: 0;
                -webkit-box-shadow: none;
                background-color: rgba(0,0,0,.15);
            }

        }
        .search-bar {
            .form-group {
                margin: 0;
            }
        }
    }

    .dropdown {
        button {
            text-align: left;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .dropdown-item {
            cursor: pointer;
        }
        .name {
            .dropdown-item-active {
                color: $blue !important;
                font-weight: 600;
            }
        }
        .checkmark {
            min-width: 16.69px;
            color: $blue;
            .icon {
                display: none;
            }
            .dropdown-item-active {
                display: block !important;
            }
        }
    }

    // .filter-options {
    //     width: 100%;
    //     position: relative;

    // }
    .filter-radio-buttons {
        overflow-y: hidden;
        overflow-x: auto;
        white-space: nowrap;
        width: 100%;

        :last-child {
            margin-right: 0 !important;
        }

        .custom-radio {
            margin-left: 0 !important;
        }
    }

    .mobile-search-toggle {
        position: sticky;
        right: 0;
        z-index: 2;
        background-color: $white;
        border-left: 1px solid $gray-300;
        display: block;
        transition: all 0.15s ease;
        &:focus,
        &:active {
            background-color: $gray-300;
            transition: all 0.15s ease;
        }
    }
}

@media (min-width: 768px) {
    .filter-bar {
        .mobile-search-toggle {
            display: none !important;
        }
        .date-range-wrapper {
            border: none !important;
        }

        .filter-and-search {
            .filter-pills {
                width: auto;
                // flex: 0.7;
                // flex: 1;
            }
            .search-bar-wrapper {
                max-width: 30rem;
                // flex: 0.3;
                // flex: 1;
            }
        }
    }
}

@media (min-width: 1024px) {
    .filter-bar {
        .filter-and-search {
            .filter-pills {
                width: auto;
                // flex: 1;
                // flex: 0.8;
            }
            .search-bar-wrapper {
                // flex: 1;
                // flex: 0.1;
            }
        }
    }
}

@media (max-width: 1296px) {
    .filter-bar {
        .filter-options {
            // flex-direction: column;
            // align-items: flex-start !important;

            .dropdown {
                width: 100%;
            }
            .dropdown-toggle {
                &::after {
                    position: absolute;
                    right: 32px;
                    top: 32px;
                }
            }

            .filter-radio-buttons {
                // padding: 0.8rem 1.3rem;
                overflow-y: hidden;
                overflow-x: auto;
                white-space: nowrap;
                width: 100%;
            }
        }

        .filter-radio-buttons {
            padding: 0.8rem 1.3rem;
            overflow-y: hidden;
            overflow-x: auto;
            white-space: nowrap;
            width: 100%;
        }

        // .filter-options {
        //     flex-direction: column;
        //     align-items: flex-start !important;

        // }
        .Manage-sub-button {
            display: none;
        }
    }
}

.active-with-tick {
    .label {
        color: $blue;
        font-weight: 500;
    }
    .tick {
        display: block !important;
        color: $blue;
    }
}
