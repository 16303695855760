.speciality-page {
    background-color: $gray-400 !important;

    .card-body {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .speciality-image {
        max-width: 800px;

        img {
            max-width: 100%;
            height: auto;
        }
    }

    .speciality-footer {
        background-color: $gray-400;
    }
}

.getting-started-dark {
    .lii-logo {
        width: 9rem;
        // margin: 0 auto;
    }
    .card {
        max-width: 32.5rem;
        .illustration {
            max-width: 8.125rem;
        }
    }
}


@include media-breakpoint-up(md) {
    .getting-started-dark {
        .lii-logo {
            width: 12rem;
        }
    }
}
