// stylelint-disable declaration-no-important

// Width and height

@each $prop, $abbrev in (width: w, height: h) {
    @each $size, $length in $sizes {
        .#{$abbrev}-#{$size} {
            #{$prop}: $length !important;
        }
    }
}

.mw-100 {
    max-width: 100% !important;
}
.mh-100 {
    max-height: 100% !important;
}
.h-100 {
    height: 100%;
}
.mw-600px {
    max-width: 37.5rem !important;
}

// Viewport additional helpers

.min-vw-100 {
    min-width: 100vw !important;
}
.min-vh-100 {
    min-height: 100vh !important;
}

.vw-100 {
    width: 100vw !important;
}
.vh-100 {
    height: 100vh !important;
}

.max-height-280 {
    max-height: 280px !important;
    overflow: auto !important;
}

//Width and height of images in px
.image-25px {
    width: 25;
    height: 25px;
}
.image-50px {
    width: 50px;
    height: 50px;
}

.image-100px {
    width: 100px;
    height: 100px;
}

.image-150px {
    width: 150px;
    height: 150px;
}

.image-200px {
    width: 200px;
    height: 200px;
}

//Width and height of images in px (Medium)
.image-md-25px {
    @media (max-width: 768px) {
        width: 25px;
        height: 25px;
    }
}
.image-md-50px {
    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    }
}

.image-md-100px {
    @media (max-width: 768px) {
        width: 100px;
        height: 100px;
    }
}

.image-md-150px {
    @media (max-width: 768px) {
        width: 150px;
        height: 150px;
    }
}

.image-md-200px {
    @media (max-width: 768px) {
        width: 200px;
        height: 200px;
    }
}

//Width and height of images in px (mobile)
.image-sm-25px {
    @media (max-width: 576px) {
        width: 25px;
        height: 25px;
    }
}

.image-sm-100px {
    @media (max-width: 576px) {
        width: 100px;
        height: 100px;
    }
}

.image-sm-150px {
    @media (max-width: 576px) {
        width: 150px;
        height: 150px;
    }
}

.image-sm-200px {
    @media (max-width: 576px) {
        width: 200px;
        height: 200px;
    }
}

// SVG
.d-block {
    svg {
        display: block !important;
        max-width: 100% !important;
        height: auto !important;
    }
}

// PIXEL WIDTHS
.w-15px {
    max-width: 15px;
}
.w-20px {
    max-width: 20px;
}
.w-25px {
    max-width: 25px;
}
.w-50px {
    max-width: 50px;
}
.w-75px {
    max-width: 75px;
}
.w-100px {
    max-width: 100px;
}
.w-125px {
    max-width: 125px;
}
.w-150px {
    max-width: 150px;
}
.w-175px {
    max-width: 175px;
}
.w-200px {
    max-width: 200px;
}
.w-225px {
    max-width: 225px;
}
.w-250px {
    max-width: 250px;
}
.w-275px {
    max-width: 275px;
}
.w-300px {
    max-width: 300px;
}
.w-320px {
    max-width: 325px;
}
.w-350px {
    max-width: 350px;
}
.w-375px {
    max-width: 375px;
}
.w-400px {
    max-width: 400px;
}
.w-425px {
    max-width: 425px;
}
.w-450px {
    max-width: 450px;
}
.w-475px {
    max-width: 475px;
}
.w-500px {
    max-width: 500px;
}
.w-1300px {
    max-width: 1300px;
}

@include media-breakpoint-up(sm) {
    .w-sm-15px {
        max-width: 15px;
    }
    .w-sm-20px {
        max-width: 20px;
    }
    .h-sm-100 {
        height: 100%;
    }
    .w-sm-25px {
        max-width: 25px;
    }
    .w-sm-50px {
        max-width: 50px;
    }
    .w-sm-75px {
        max-width: 75px;
    }
    .w-sm-100px {
        max-width: 100px;
    }
    .w-sm-125px {
        max-width: 125px;
    }
    .w-sm-150px {
        max-width: 150px;
    }
    .w-sm-175px {
        max-width: 175px;
    }
    .w-sm-200px {
        max-width: 200px;
    }
    .w-sm-225px {
        max-width: 225px;
    }
    .w-sm-250px {
        max-width: 250px;
    }
    .w-sm-275px {
        max-width: 275px;
    }
    .w-sm-300px {
        max-width: 300px;
    }
    .w-sm-320px {
        max-width: 325px;
    }
    .w-sm-350px {
        max-width: 350px;
    }
    .w-sm-375px {
        max-width: 375px;
    }
    .w-sm-400px {
        max-width: 400px;
    }
    .w-sm-425px {
        max-width: 425px;
    }
    .w-sm-450px {
        max-width: 450px;
    }
    .w-sm-475px {
        max-width: 475px;
    }
    .w-sm-500px {
        max-width: 500px;
    }
    .w-sm-1300px {
        max-width: 1300px;
    }
}
@include media-breakpoint-up(md) {
    .w-md-15px {
        max-width: 15px;
    }
    .w-md-20px {
        max-width: 20px;
    }
    .h-md-100 {
        height: 100%;
    }
    .w-md-25px {
        max-width: 25px;
    }
    .w-md-50px {
        max-width: 50px;
    }
    .w-md-75px {
        max-width: 75px;
    }
    .w-md-100px {
        max-width: 100px;
    }
    .w-md-125px {
        max-width: 125px;
    }
    .w-md-150px {
        max-width: 150px;
    }
    .w-md-175px {
        max-width: 175px;
    }
    .w-md-200px {
        max-width: 200px;
    }
    .w-md-225px {
        max-width: 225px;
    }
    .w-md-250px {
        max-width: 250px;
    }
    .w-md-275px {
        max-width: 275px;
    }
    .w-md-300px {
        max-width: 300px;
    }
    .w-md-320px {
        max-width: 325px;
    }
    .w-md-350px {
        max-width: 350px;
    }
    .w-md-375px {
        max-width: 375px;
    }
    .w-md-400px {
        max-width: 400px;
    }
    .w-md-425px {
        max-width: 425px;
    }
    .w-md-450px {
        max-width: 450px;
    }
    .w-md-475px {
        max-width: 475px;
    }
    .w-md-500px {
        max-width: 500px;
    }
    .w-md-1300px {
        max-width: 1300px;
    }
}
@include media-breakpoint-up(lg) {
    .h-lg-100 {
        height: 100%;
    }
    .w-lg-15px {
        max-width: 15px;
    }
    .w-lg-20px {
        max-width: 20px;
    }
    .w-lg-25px {
        max-width: 25px;
    }
    .w-lg-50px {
        max-width: 50px;
    }
    .w-lg-75px {
        max-width: 75px;
    }
    .w-lg-100px {
        max-width: 100px;
    }
    .w-lg-125px {
        max-width: 125px;
    }
    .w-lg-150px {
        max-width: 150px;
    }
    .w-lg-175px {
        max-width: 175px;
    }
    .w-lg-200px {
        max-width: 200px;
    }
    .w-lg-225px {
        max-width: 225px;
    }
    .w-lg-250px {
        max-width: 250px;
    }
    .w-lg-275px {
        max-width: 275px;
    }
    .w-lg-300px {
        max-width: 300px;
    }
    .w-lg-320px {
        max-width: 325px;
    }
    .w-lg-350px {
        max-width: 350px;
    }
    .w-lg-375px {
        max-width: 375px;
    }
    .w-lg-400px {
        max-width: 400px;
    }
    .w-lg-425px {
        max-width: 425px;
    }
    .w-lg-450px {
        max-width: 450px;
    }
    .w-lg-475px {
        max-width: 475px;
    }
    .w-lg-500px {
        max-width: 500px;
    }
    .w-lg-1300px {
        max-width: 1300px;
    }
}
@include media-breakpoint-up(xl) {
    .w-xl-15px {
        max-width: 15px;
    }
    .w-xl-20px {
        max-width: 20px;
    }
    .h-xl-100 {
        height: 100%;
    }
    .w-xl-25px {
        max-width: 25px;
    }
    .w-xl-50px {
        max-width: 50px;
    }
    .w-xl-75px {
        max-width: 75px;
    }
    .w-xl-100px {
        max-width: 100px;
    }
    .w-xl-125px {
        max-width: 125px;
    }
    .w-xl-150px {
        max-width: 150px;
    }
    .w-xl-175px {
        max-width: 175px;
    }
    .w-xl-200px {
        max-width: 200px;
    }
    .w-xl-225px {
        max-width: 225px;
    }
    .w-xl-250px {
        max-width: 250px;
    }
    .w-xl-275px {
        max-width: 275px;
    }
    .w-xl-300px {
        max-width: 300px;
    }
    .w-xl-320px {
        max-width: 325px;
    }
    .w-xl-350px {
        max-width: 350px;
    }
    .w-xl-375px {
        max-width: 375px;
    }
    .w-xl-400px {
        max-width: 400px;
    }
    .w-xl-425px {
        max-width: 425px;
    }
    .w-xl-450px {
        max-width: 450px;
    }
    .w-xl-475px {
        max-width: 475px;
    }
    .w-xl-500px {
        max-width: 500px;
    }
    .w-xl-1300px {
        max-width: 1300px;
    }
}
