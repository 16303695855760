.release-notes {
    .version-container {
        .version {
            margin-right: 4rem;
            width: 35%;
            max-width: 21.875rem;
        }
        .notes {
            color: $blue-zodiac;
            width: 65%;
        }
    }
}

@media (max-width: 766px) {
    .release-notes {
        .version-container {
            flex-direction: column;
            margin-right: 0;
        }
        .notes {
            margin-top: 0.5rem;
        }
    }
}
