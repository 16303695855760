.header-section-wrapper {
    position: relative;
    z-index: 5;
    border-top: 1px solid $gray-300;
}

.profile-header-bar {
    background-color: $white;
    border-bottom: 1px solid $gray-400;
    .profile-name {
        h1 {
            margin: 0;
            padding: 0;
        }
        .avatar {
            width: 5rem;
            height: 5rem;
            svg {
                display: block;
                width: 100%;
            }
        }
    }
}

.profile-content-block {
    border-bottom: 1px solid $gray-400;

    .info-block {
        font-size: 0.938rem;

        h5 {
            font-size: 0.938rem;
        }
    }
}

//HEADER BAR
.header-bar {
    padding: 1rem 0;
    h1 {
        margin: 0;
        font-size: 1.25rem;
        white-space: normal;
    }
}

.profile-name {
    h1 {
        margin: 0;
        padding: 0;
    }
    .avatar {
        width: 2rem;
        svg {
            display: block;
            width: 100%;
        }
    }
}


.header-strip-small {
    font-size: 0.938rem;
}

// DIRECT PROFILE PAGE
.direct-profile-page-header {
    .return-to-dashboard {
        background-color: $white;
        border-bottom: 1px solid $gray-400;
        padding: 0.5rem 1.5rem;
        font-size: 0.938rem;
    }
    .direct-profile-header-bar {
        padding: 1rem 1.5rem;
        background-color: $white;
        border-bottom: 1px solid $gray-400;

        .profile-name {
            h1 {
                font-size: 1.375rem;
                margin: 0;
                padding: 0;
            }
        }
        // .profile-icon {
        //     svg {
        //         width: 27px;
        //         height: 27px;
        //     }
        // }
    }
    .direct-nav-tabs-wrapper {
        padding: 0 1.5rem;
        .nav-link {
            font-weight: 400;
            padding: 1rem 1.2rem;
        }
        .active {
            font-weight: 600;
        }
    }

    @include media-breakpoint-up(md) {
        .return-to-dashboard {
            padding: 0.5rem 2rem;
            font-size: 1rem;
        }
        .direct-profile-header-bar {
            padding: 2.5rem 2rem;

            .profile-name {
                h1 {
                    font-size: 1.625rem;
                }
            }
            .profile-icon {
                svg {
                    width: 36px;
                    height: 36px;
                }
            }
        }
        .direct-nav-tabs-wrapper {
            padding: 0 2rem;
            .nav-link {
                padding: 1.5rem 1.25rem;
            }
        }
    }
}

.dropdown-header-title-with-icon {
    .dropdown-toggle {
        align-items: center;
    }
    .dropdown-item {
        cursor: pointer;
    }
    .icon-and-name {
        .globe {
            font-size: inherit;
        }
        .dropdown-item-active {
            color: $blue !important;
            font-weight: 600;
        }
    }
    .globe {
        color: $lii-blue;
        font-size: 1.25rem;
    }
    .checkmark {
        min-width: 16.69px;
        color: $blue;
        .icon {
            display: none;
        }
        .dropdown-item-active {
            display: block !important;
        }
    }
}

.clearance-header-bar {
    background-color: $white;

    .magnifying-glass-mobile {
        display: none;
    }

    @media (max-width: 1240px) {
        .magnifying-glass-mobile {
            display: block;
        }

        .search-bar {
            display: none;
        }
    }

    @include media-breakpoint-down(sm) {
        .header-with-icon {
            h1 {
                font-size: 18px;
            }
        }
    }
}

.single-clearance-header-bar {
    background-color: $white;
    border-bottom: 1px solid $gray-300;

    .entry-no {
        font-size: 1.25rem;
    }
    .shipment-info {
        font-size: 0.938rem;
        span {
            color: $blue-zodiac;
        }
        span:last-child {
            opacity: 0.7;
        }
    }
    .subscribe-btn {
        bottom: 0;
        right: 0;

        @include media-breakpoint-up(md) {
            bottom: 15px;
        }
    }
}

.header-bar-with-logo {
    background-color: $white;
    // padding: 1rem 1.5rem;

    .image-container {
        img {
            max-width: 5rem;
            height: auto;
        }
    }
    .powered-by {
        p {
            font-size: 0.875rem;
            color: $secondary;
        }
    }
}

.centered-header {
    img {
        max-width: 6.25rem;
    }
    p {
        max-width: 23.75rem;
        margin: 0 auto;
    }
}

@include media-breakpoint-down(sm) {
    .header-bar-with-logo {
        flex-wrap: wrap;
        // padding: 1rem 1.5rem;

        .title-and-image {
            h1 {
                font-size: 1.125rem;
            }
            .image-container {
                img {
                    max-width: 2.813rem;
                    max-height: 2.813rem;
                }
            }
        }
    }
}

//POWERED BY STRIPE
.powered-by {
    p {
        font-size: 0.75rem;
    }

    .stripe-logo {
        max-width: 2.5rem;
        height: auto;
    }
}

@include media-breakpoint-up(md) {
    //HEADER BAR
    .header-bar {
        padding: 1.5rem 0;
        h1, .entry-no {
            font-size: 1.75rem;
        }
    }
    .profile-name {
        .avatar {
            width: 2.35rem;
        }
    }
    .single-clearance-header-bar {
        h1, .entry-no {
            font-size: 1.75rem;
        }
    }
    
}
@include media-breakpoint-up(lg) {
    //HEADER BAR
    .header-bar {
        padding: 2rem 0;
    }
}

.lii-logo-header {
    .logo {
        width: 14.375rem;
    }
}
