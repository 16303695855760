// sidebar container
.sidebar {
    min-width: 270px;
    max-width: 270px;
    height: 100vh;
    transition: all 0.3s;
    position: relative;
    background: transparent;
    margin-left: -270px;
    background-color: $secondary;
    z-index: 1025;
    overflow: auto;

    a.need-help {
        color: $gray-700;
    }

    .inner {
        // position: absolute;
        top: 0;
        left: 0;
        min-width: 270px;
        max-width: 270px;
        height: 100vh;
        font-size: 0.9rem;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
    }
    &.showing {
        margin-left: 0;
    }
    .brand-color-strip {
        width: 100;
        display: block;
        height: 0.625rem;
        &.lii-portal-accent {
            background-color: $lii-portal-accent-color;
        }
        &.lii-direct-accent {
            background-color: $lii-direct-accent-color;
        }
    }
    .brand-logo {
        padding: 2rem 1.5rem 2rem 1.5rem;
        svg {
            display: block;
            max-width: 100%;
        }
    }

    .toggle {
        .chevron-down {
            svg {
                width: auto;
                margin-right: 0;
            }
            transform: rotate(180deg) !important;
        }
        &.collapsed {
            .chevron-down {
                transform: rotate(0deg) !important;
            }
        }
    }

    @media (max-width: 1024px) {
        position: fixed;
        height: 100%;
    }
}

//sidebar header
.sidebar-header {
    position: relative;

    .sidebar-close-button-mobile {
        display: none;
    }

    @media (max-width: 1024px) {
        .sidebar-close-button-mobile {
            display: block;
            padding: 8px 12px;
        }
    }

    img,
    svg {
        max-width: 100%;
        display: block;
    }
}

// shared styles for accordion and listgroup
.sidebar-accordion,
.sidebar-list-group {
    .sidebar-accordion-item,
    .sidebar-list-group-item {
        // padding: 1.15rem 1.5rem;
        display: block;
        color: $gray-700;
        font-weight: 500;
        font-size: 1rem;
        margin: 0;
        border: none;
        border-radius: 0;
        background-color: $secondary;
        cursor: pointer;

        svg {
            width: 1.5rem;
            margin-right: 0.875rem;
        }
        .toggle {
            padding: 1.1rem 1.5rem;
        }
        //hover
        &:hover {
            color: #fff;
            cursor: pointer;
            text-decoration: none;
            position: relative;
            // &::before {
            //     content: '';
            //     width: 0.3rem;
            //     height: 100%;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     background-color: #0c78e3;
            // }
            div {
                color: #fff !important;
            }
        }

        .sidebar-links {
            color: $gray-700;
            text-decoration: none;

            &:hover {
                color: #fff;
                background-color: $blue-zodiac-light;
            }

            // &::before {
            //     content: "";
            //     width: 0.3rem;
            //     height: 100%;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     background-color: #0c78e3;
            // }
        }
    }
    .sidebar-list-group-item {
        padding: 1.1rem 1.5rem;
        &:hover {
            background-color: $blue-zodiac-light;
            color: #fff;
            cursor: pointer;
            text-decoration: none;
            position: relative;
        }
        &.active {
            border: none;
            border-radius: 0;
            position: relative;
        }
    }
    .menu-item--active {
        .sidebar-links {
            &:hover {
                background-color: $lii-blue !important;
            }
        }
        position: relative;
        background-color: $lii-blue !important;
        // &::before {
        //     content: '';
        //     width: 0.3rem;
        //     height: 100%;
        //     position: absolute;
        //     top: 0;
        //     left: 0;
        //     background-color: #0c78e3;
        // }
        span {
            color: #fff !important;
        }
    }
    .sidebar-list-group-item--accordion-router-link {
        &:hover {
            text-decoration: none;
        }
        &.active {
            background-color: $blue-dark-1100;
            border: none;
            border-radius: 0;
            position: relative;
            color: $white;

            // &::before {
            //     content: '';
            //     width: 0.3rem;
            //     height: 100%;
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     background-color: #0c78e3;
            // }
        }
    }
}

// accordion
.sidebar-accordion {
    a[aria-expanded='true'] {
        // background-color: $blue-dark-1100;
    }
    .sidebar-accordion-item {
        .chevron-down {
            svg {
                width: auto;
                margin-right: 0;
            }
            transform: rotate(180deg);
        }
        &.collapsed {
            .chevron-down {
                transform: rotate(0deg);
            }
        }

        .sidebar-nested-list {
            background-color: $blue-dark-1100;
            ul {
                padding-top: 0.7rem;
                padding-bottom: 0.7rem;
                margin-bottom: 0;
                list-style: none;
                padding-left: 0;
                li {
                    a {
                        padding: 0.7rem 1.5rem 0.7rem 4rem !important;
                        color: $gray-700;
                        font-size: 0.875rem;
                        display: block;
                        font-weight: 500;
                    }
                    &.active {
                        a {
                            color: $white !important;
                        }
                    }
                    &:hover {
                        a {
                            color: $white !important;
                            text-decoration: none;
                        }
                    }
                }
            }
            .sub-item--active {
                color: #fff !important;
            }
        }
    }
}

.sidebar-footer {
    padding: 0;
    // padding: 1.5rem 1.5rem 2rem 1.5rem;
    .sidebar-accordion,
    .sidebar-list-group {
        margin-top: 1.2rem;
        margin-bottom: 1.2rem;
        .sidebar-accordion-item,
        .sidebar-list-group-item {
            padding: 0.6rem 0;
            display: block;
            color: $gray-700;
            font-weight: 500;
            font-size: 1rem;
            margin: 0;
            border: none;
            border-radius: 0;
            background-color: $secondary;
            cursor: pointer;

            svg {
                width: 1.5rem;
                margin-right: 0.875rem;
            }
            &:hover {
                color: #fff;
                cursor: pointer;
                background-color: transparent;
                text-decoration: none;
                position: relative;
                &::before {
                    content: none;
                }
            }

            &.active {
                background-color: transparent;
                border: none;
                border-radius: 0;
                position: relative;
                color: $white;
            }
        }
    }
    h5 {
        color: $white;
        font-size: 14px;
        text-transform: none;
        font-weight: 500;
        margin-bottom: 1rem;
    }
    .help-items {
        color: $lii-text-light;
        &:hover div {
            color: #fff;
            background-color: transparent;
            position: relative;
            &::before {
                content: none;
            }
        }
        &:hover {
            cursor: pointer;
            text-decoration: none;
        }
    }

    .dropdown {
        .dropdown-toggle {
            align-items: center;

            &::after {
                position: absolute;
                right: 17px;
            }
        }
        .dropdown-item {
            cursor: pointer;
        }
        .icon-and-name {
            .globe {
                color: $lii-blue;
            }
            .dropdown-item-active {
                color: $blue !important;
                font-weight: 600;
            }
        }
        .checkmark {
            min-width: 16.69px;
            color: $blue;
            .icon {
                display: none;
            }
            .dropdown-item-active {
                display: block !important;
            }
        }
    }
    .inner-padding {
        padding: 0 1.5rem;
    }
    a.live-chat {
        font-size: 1rem;
        color: $white;
        display: block;
        border-top: 1px solid $gray-900;
        border-bottom: 1px solid $gray-900;
        transition: all 0.2s ease;
        &:hover, &:focus {
            text-decoration: none;
            background-color: $blue-zodiac-light;
            transition: all 0.2s ease;
        }
        &:active {
            background-color: $blue-zodiac-dark;
        }
        .badge {
            font-size: 0.6rem;
            letter-spacing: 0.065rem;
        }
        .live-chat-icon-and-text {
            position: relative;
            .red-circle {
                position: absolute;
                top: -5px;
                right: -12px;
            }
        }
    }
    a.contact-livingston {
        font-size: 1rem;
        font-weight: 500;
        color: $lii-text-light;
        display: block;
        transition: all 0.2s ease;
        &:hover, &:focus {
            text-decoration: none;
            background-color: $blue-zodiac-light;
            transition: all 0.2s ease;
        }
        &:active {
            background-color: $blue-zodiac-dark;
        }
    }
}

.sidebar-versioning {
    a {
        color: $gray-700;
        font-weight: 400;
        text-decoration: none;

        &:hover {
            color: #fff;
        }
    }
}

@media (max-width: 1024px) {
    .overlay {
        position: fixed;
        display: block;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(12, 38, 68, 0.6);
        z-index: 1024;
    }
}
