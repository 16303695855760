.error-page-container {
    background-color: $gray-200;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 2rem;
    padding-top: 0;

    @include media-breakpoint-up(sm) {
        padding-bottom: 3rem;
    }

    .error-image {
        text-align: center;

        img {
            max-width: 54%;
            height: auto;
        }
    }

    .title {
        text-align: center;
        font-size: 1.75rem;
    }

    .desc {
        text-align: center;
        color: $lii-text;

        a {
            font-weight: 400;
            text-decoration: underline;
        }
    }
}
