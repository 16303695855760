.clearance-table,
.profile-subscription-tables,
.teams-landing-table,
.clients-and-permissions-table,
.add-new-user-table,
.add-new-subscriber-table,
.subscriber-landing-table,
.subscriber-to-single-clearance,
.clients-table,
.messages-table {
    font-size: 0.875rem !important;
    border: 1px solid $gray-600;
    // border-radius: 12px;
    border-radius: 0;
    overflow: hidden;
    // height: 100%;

    .k-grid-content {
        background-color: $gray-200;
    }

    table {
        th,
        .k-grid-header-sticky {
            background-color: $gray-200;
            font-weight: 500;
            color: $secondary;
            .k-cell-inner > .k-link {
                padding: 12px 8px;
            }
        }
        td {
            padding: 12px 8px;
            color: $lii-text;
            a {
                color: $blue;
                text-decoration: underline;
                font-weight: 400;
            }
        }

        [dataRedBgRow='red-bg-row'],
        .red-bg-row {
            td {
                background-color: lighten($red-light, 4%);
                border-color: darken($red-light, 2%);
            }
            &:hover {
                td {
                    background-color: darken($red-light, 1%);
                    border-color: darken($red-light, 5%);
                }
            }
        }
        tr.k-alt,
        tr.k-alt .k-grid-content-sticky {
            background-color: $gray-100;
        }
        tr,
        tr .k-grid-content-sticky {
            background-color: $white;
        }
        .k-grid-header-wrap,
        .k-grouping-header,
        .k-grouping-header .k-group-indicator,
        td,
        .k-grid-footer,
        .k-grid-footer-wrap,
        .k-grid-content-locked,
        .k-grid-footer-locked,
        .k-grid-header-locked,
        .k-filter-row > td,
        .k-filter-row > th {
            border-color: $gray-400;
        }
        .k-grid-header,
        .k-header,
        th.k-header {
            border-color: $gray-600;
        }
        tbody tr:not(.k-detail-row):hover,
        .tbody tr:not(.k-detail-row).k-state-hover {
            background-color: $gray-200;
            box-shadow: 0px 1px 6px rgba(16, 105, 201, 0.12);
            position: relative;
            z-index: 5;
            cursor: pointer;
            td {
                color: $primary;
            }
            .k-grid-content-sticky {
                background-color: $gray-200;
            }
        }

        .k-master-row [style='background-color: rgb(253, 239, 242)'] {
            font-size: 50px !important;
        }

        .badge.badge-pill {
            font-size: 0.813rem;
        }
        .k-grid-content-sticky,
        .k-grid-header-sticky {
            // border: none !important;
            // border-left: 1px solid $gray-300 !important;
            // border-right: 1px solid $gray-300 !important;
        }

        // BUTTONS
        //-------------------
        .k-grid-column-menu {
            border: 1px solid transparent;
            background-color: transparent;
            color: $lii-text;
            border-radius: $btn-border-radius-sm;
            @include hover() {
                background-color: rgba($lii-text, 0.08);
                border: 1px solid rgba($lii-text, 0.03);
                color: $lii-text;
            }
            &:focus,
            &:active,
            &.focus {
                background-color: rgba($lii-text, 0.2) !important;
                border: 1px solid rgba($lii-text, 0.03);
                // box-shadow: 0 0 0 $input-btn-focus-width rgba($lii-text, 0.2) !important;
                color: $lii-text !important;
            }
            &.disabled,
            &:disabled {
                color: $lii-text;
                background-color: transparent;
            }
        }
    }
    .k-grid-header .k-i-sort-asc-sm,
    .k-grid-header .k-i-sort-desc-sm,
    .k-grid-header .k-sort-order {
        color: $primary;
    }

    // PAGINATION FOOTER
    //-------------------
    .k-pager-wrap {
        background-color: white;
        padding: 12px 10px 94px 10px;
    }
    .k-pager-numbers .k-link,
    .k-pager-numbers .k-link.k-state-selected {
        color: $primary;
    }
    .k-pager-numbers .k-link.k-state-selected,
    .k-pager-numbers .k-link:hover,
    .k-pager-nav.k-link:hover {
        background-color: $gray-200;
        border-radius: 6px;
        color: $primary;
    }
    .k-pager-numbers .k-link:focus,
    .k-pager-nav.k-link:focus {
        box-shadow: none;
        background-color: $gray-300;
        border-radius: 6px;
    }
    .k-pager-sizes,
    .k-pager-info {
        color: $lii-text;
    }
    .k-pager-info {
        font-weight: 500;
    }
    .k-picker {
        border: $btn-border-width solid $gray-600;
        background-color: white;
        &:hover {
            background-color: $gray-200;
            color: $lii-text;
        }
        &:focus {
            background-color: darken(rgba($gray-200, 1), $amount: 5) !important;
            border: $btn-border-width solid $gray-600 !important;
            box-shadow: 0 0 0 $input-btn-focus-width rgba(rgba($gray-200, 1), 1) !important;
            color: $lii-text !important;
        }
    }

    .k-pager-sizes,
    .k-pager-info {
        position: absolute;
        margin-left: 0.5rem;
    }
    .k-pager-sizes {
        top: 3.5rem;
    }
    .k-pager-info {
        top: 6.2rem;
    }

    .loading {
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            background: $white;
            opacity: 0.8;
            z-index: 5;
        }
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            margin-top: -1rem;
            margin-left: -1rem;
            z-index: 6;
            color: $blue;
            display: inline-block;
            width: 2rem;
            height: 2rem;
            vertical-align: text-bottom;
            border: 0.25em solid currentColor;
            border-right-color: transparent;
            border-radius: 50%;
            animation: spinner-border 0.75s linear infinite;
        }
    }

    .k-column-title {
        white-space: normal !important;
    }
}

.k-list-item.k-selected,
.k-list-item.k-selected:hover,
.k-selected.k-list-optionlabel {
    background-color: $primary;
}
.k-list-item:hover {
    background-color: #ecf5fd;
    color: $primary;
}

// STICKY COLUMNS
.k-master-row {
    td,
    th {
        &.k-grid-content-sticky {
            border-color: #e3ebf1;
        }
        &:first-child {
            &.k-grid-content-sticky {
                border-left-width: 0 !important;
            }
        }
        &:last-child {
            &.k-grid-content-sticky {
                border-right-width: 0 !important;
            }
        }
    }
}

//FILTER BUTTONS
//-----------------
.k-grid-columnmenu-popup {
    font-family: $font-family-sans-serif;
}
.k-button {
    &.k-button-solid-primary {
        background-color: $primary;
        border-color: $primary;
        font-weight: 500;
        &:hover {
            background-color: #0057ad;
            border-color: #0057ad;
        }
        &:focus,
        &.focus {
            background-color: #005dba;
            border-color: #005dba;
            box-shadow: none !important;
        }
    }
    &.k-button-solid-base {
        background-color: transparent;
        border: $btn-border-width solid $gray-600;
        color: $lii-text;
        font-weight: 500;
        &:hover {
            background-color: $gray-200;
            border: $btn-border-width solid $gray-600;
        }
        &:focus,
        &.focus {
            background-color: darken(rgba($gray-200, 1), $amount: 5) !important;
            border: $btn-border-width solid $gray-600 !important;
            box-shadow: none !important;
            color: $lii-text !important;
        }
    }
}
.k-searchbox,
.k-pager-sizes {
    .k-button-solid-base {
        border: none;
        &:hover {
            border: none;
        }
        &:focus,
        &.focus {
            border: none !important;
        }
    }
}

//FILTER CHECKBOXES
//-----------------
.k-checkbox {
    border-color: $custom-control-indicator-border-color;
}
.k-checkbox:checked,
.k-checkbox.k-checked {
    background-color: $primary;
    border-color: $primary;
}
.k-checkbox:checked:focus,
.k-checkbox.k-checked.k-focus {
    box-shadow: none;
}

//ROW SELECTED
.k-master-row.k-state-selected {
    // background-color: $blue-200 !important;
}

.k-grid td.k-state-selected,
.k-grid tr.k-state-selected > td {
    background-color: $blue-200 !important;
    box-shadow: inset 0px -1px 0px darken($blue-200, 5%) !important;
}

@media (min-width: 768px) {
    .clearance-table,
    .profile-subscription-tables,
    .teams-landing-table,
    .clients-and-permissions-table,
    .add-new-user-table,
    .add-new-subscriber-table,
    .subscriber-landing-table,
    .subscriber-to-single-clearance,
    .clients-table,
    .messages-table {
        // height: auto;

        // height: 100%;

        // max-height: 500px;
        // .k-grid-content {
        //     overflow-y: auto;
        // }
    }
}
@media (min-width: 840px) {
    .clearance-table,
    .profile-subscription-tables,
    .teams-landing-table,
    .clients-and-permissions-table,
    .add-new-user-table,
    .add-new-subscriber-table,
    .subscriber-landing-table,
    .subscriber-to-single-clearance,
    .clients-table,
    .messages-table {
        // PAGINATION FOOTER
        //-------------------
        .k-pager-wrap {
            padding: 12px 10px;
        }
        .k-pager-sizes,
        .k-pager-info {
            position: relative;
            margin-left: 1rem;
        }
        .k-pager-sizes {
            top: auto;
        }
        .k-pager-info {
            top: auto;
        }
    }
}

// CLEARANCE TABLE
.clearance-table {
    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 210px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 180px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 130px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 130px;
    }
    .k-grid-header col:nth-of-type(5),
    .k-grid-table col:nth-of-type(5) {
        width: 110px;
    }
    .k-grid-header col:nth-of-type(6),
    .k-grid-table col:nth-of-type(6) {
        width: 190px;
    }
    .k-grid-header col:nth-of-type(7),
    .k-grid-table col:nth-of-type(7) {
        width: 140px;
    }
    .k-grid-header col:nth-of-type(8),
    .k-grid-table col:nth-of-type(8) {
        width: 100px;
    }
    .k-grid-header col:nth-of-type(9),
    .k-grid-table col:nth-of-type(9) {
        width: 90px;
    }
    .k-grid-header col:nth-of-type(10),
    .k-grid-table col:nth-of-type(10) {
        width: 125px;
    }
}

// PROFILE SUBSCRIPTION TABLE
.profile-subscription-tables {
    // overflow: visible !important;

    .k-grid-container {
        // min-height: 400px;
        // overflow: visible !important;
    }

    .k-grid-content {
        // overflow: visible !important;
        .k-master-row {
            &:hover > td {
                color: inherit !important;
                cursor: default;
            }
        }
    }

    //DROPDOWN VISIBILITY
    .k-master-row td {
        overflow: visible !important;
    }

    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 80px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 210px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 150px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 160px;
    }
    .k-grid-header col:nth-of-type(5),
    .k-grid-table col:nth-of-type(5) {
        width: 140px;
    }
    .k-grid-header col:nth-of-type(6),
    .k-grid-table col:nth-of-type(6) {
        width: 150px;
    }

    @media (max-width: 840px) {
        height: 100% !important;
    }

    // Remove vertical scrollbar
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-content {
        overflow-y: visible;
    }
}

// TABLE - MILESTONE DROPDOWN
.table-milestone-dropdown {
    .dropdown-menu {
        min-width: 300px;

        form {
            padding: 1.2rem;
            border-bottom: 1px solid #d4dee8;
        }

        .button-container {
            padding: 1.2rem;
        }
    }
}

.clearance-table-header {
    .result-text {
        span:nth-last-child(2) {
            border-right: 1px solid $gray-600;
        }

        .bold-text {
            font-weight: 500;
        }

        .reset {
            color: $blue;
            font-weight: 500;
            cursor: pointer;
        }
    }

    @media (min-width: 1296px) {
        .dropdown {
            display: none;
        }
    }
}

//ROW WITH BUTTON
.row-with-button {
    order: 2;
    width: 100%;
    padding: 0.5rem 3rem;
    padding-left: 4.7%;
    border-bottom: 1px solid $gray-200;
}

//TEAMS LANDING PAGE TABLE
.teams-landing-table {
    //FLEX ELEMENT TO ADD THE ROW WITH NEW USER BUTTON
    display: flex;
    flex-direction: column;
    .k-grid-header {
        order: 1;
    }
    .k-grid-container {
        order: 3;
    }
    .k-pager-wrap {
        order: 4;
    }

    .k-master-row td {
        overflow: visible !important;
    }

    .k-grid-container {
        // min-height: 400px;
        // overflow: visible !important;
    }

    @media (max-width: 840px) {
        height: 100% !important;
    }

    // COLUMN WIDTHS
    //-------------------
    // .k-grid-header col:nth-of-type(1),
    // .k-grid-table col:nth-of-type(1) {
    //     width: 50px;
    // }

    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 200px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 280px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 100px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 120px;
    }
    .k-grid-header col:nth-of-type(5),
    .k-grid-table col:nth-of-type(5) {
        width: 120px;
    }
    .k-grid-header col:nth-of-type(6),
    .k-grid-table col:nth-of-type(6) {
        width: 50px;
    }

    //Remove heading on button column
    .k-grid-header {
        .k-header:last-of-type {
            .k-cell-inner {
                display: none;
            }
        }
    }
}

// CLIENTS AND PERMISSIONS TABLE
.clients-and-permissions-table {
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;

    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col,
    .k-grid-table col {
        width: 220px;
    }
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 180px !important;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 180px !important;
    }

    @media (min-width: 1500px) {
        .k-grid-header col,
        .k-grid-table col {
            width: auto;
        }
    }

    //CENTER HEADINGS
    .k-cell-inner .k-link {
        display: block;
    }

    //ALIGN HEADING AT THE TOP
    .k-header {
        vertical-align: top;
    }

    //DEFAULT HOVER OVER CONTENT
    .k-grid-content {
        .k-master-row {
            &:hover > td {
                color: $lii-text !important;
                cursor: default;
            }
        }
    }

    //DEFAULT HOVER OVER HEADER
    .k-cell-inner {
        .k-link {
            &:hover {
                cursor: default;
            }
        }
    }

    // Remove vertical scrollbar
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-content {
        overflow-y: visible;
    }
}

//SELECT ALL CLICKABLE
.table-column-select-all {
    color: $blue;
    font-size: 0.875rem;
    cursor: pointer;
    font-weight: 400;
}

//TABLE HEADING
.table-heading {
    color: $blue-zodiac;
    background-color: $gray-500;
    font-size: 0.938rem;
    font-weight: 500;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
    border: 1px solid $gray-600;
    border-bottom: none;
}

//ADD NEW USER TABLE
.add-new-user-table {
    border-radius: $border-radius;

    // Remove extra white-space in table
    height: auto;

    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 200px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 240px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 200px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 145px;
    }
    .k-grid-header col:nth-of-type(5),
    .k-grid-table col:nth-of-type(5) {
        width: 200px;
    }
    .k-grid-header col:nth-of-type(6),
    .k-grid-table col:nth-of-type(6) {
        width: 60px;
    }

    //Remove heading on button column
    .k-grid-header {
        .k-header:last-of-type {
            .k-cell-inner {
                display: none;
            }
        }
    }

    // Default hover over content + no records row
    .k-grid-content {
        .k-master-row {
            &:hover > td {
                color: $lii-text;
                cursor: default;
            }
        }
        .k-grid-norecords {
            td {
                color: $lii-text-light;
            }
            &:hover {
                cursor: default;
                td {
                    color: $lii-text-light;
                }
            }
        }
    }

    // Default hover over header
    .k-cell-inner {
        .k-link {
            &:hover {
                cursor: default;
            }
        }
    }

    // Remove vertical scrollbar
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-content {
        overflow-y: visible;
    }
}

// NEW ROW HIGHLIGHT
.new-row-highlight,
.new-row-highlight .k-grid-content-sticky {
    animation-name: newRowHighlight;
    animation-duration: 5s;

    &.k-alt {
        animation-name: newRowHighlightAlt;
    }
}

@keyframes newRowHighlight {
    0% {
        background-color: $green-light;
    }

    100% {
        background-color: $white;
    }
}

@keyframes newRowHighlightAlt {
    0% {
        background-color: $green-light;
    }

    100% {
        background-color: $gray-100;
    }
}

.add-new-subscriber-table {
    border-radius: $border-radius;

    // Remove extra white-space in table
    height: auto;

    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 400px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 220px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 180px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 60px;
    }

    // Default hover over content + no records row
    .k-grid-content {
        .k-master-row {
            &:hover > td {
                color: $lii-text;
                cursor: default;
            }
        }
        .k-grid-norecords {
            td {
                color: $lii-text-light;
            }
            &:hover {
                cursor: default;
                td {
                    color: $lii-text-light;
                }
            }
        }
    }

    //Remove heading on button column
    .k-grid-header {
        .k-header:last-of-type {
            .k-cell-inner {
                display: none;
            }
        }
    }

    // Default hover over header
    .k-cell-inner {
        .k-link {
            &:hover {
                cursor: default;
            }
        }
    }

    // Remove vertical scrollbar
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-content {
        overflow-y: visible;
    }
}

.subscriber-landing-table {
    //FLEX ELEMENT TO ADD THE ROW WITH NEW USER BUTTON
    display: flex;
    flex-direction: column;
    .k-grid-header {
        order: 1;
    }
    .k-grid-container {
        order: 3;
    }
    .k-pager-wrap {
        order: 4;
    }

    .k-master-row td {
        overflow: visible !important;
    }

    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 50px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 320px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 160px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 230px;
    }
    .k-grid-header col:nth-of-type(5),
    .k-grid-table col:nth-of-type(5) {
        width: 120px;
    }
    .k-grid-header col:nth-of-type(6),
    .k-grid-table col:nth-of-type(6) {
        width: 140px;
    }
    .k-grid-header col:nth-of-type(7),
    .k-grid-table col:nth-of-type(7) {
        width: 50px;
    }

    //Remove heading on button column
    .k-grid-header {
        .k-header:last-of-type {
            .k-cell-inner {
                display: none;
            }
        }
    }

    .k-grid-container {
        // min-height: 400px;
    }

    //CENTER CHECKBOX FOR HEADER - SUB TABLE
    .k-grid-header .k-header:first-child {
        text-align: center !important;
    }
}

// CENTER CHECKBOX FOR DATA - SUBSCRIPTION TABLE
.subscriber-landing-table.k-grid td:first-child {
    text-align: center !important;
}

.subscriber-to-single-clearance {
    //FLEX ELEMENT TO ADD THE ROW WITH NEW USER BUTTON
    display: flex;
    flex-direction: column;
    .k-grid-header {
        order: 1;
    }
    .k-grid-container {
        order: 3;
    }
    .k-pager-wrap {
        order: 4;
    }

    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 50px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 320px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 130px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 180px;
    }
    .k-grid-header col:nth-of-type(5),
    .k-grid-table col:nth-of-type(5) {
        width: 160px;
    }
    .k-grid-header col:nth-of-type(6),
    .k-grid-table col:nth-of-type(6) {
        width: 130px;
    }
    .k-grid-header col:nth-of-type(7),
    .k-grid-table col:nth-of-type(7) {
        width: 150px;
    }
    .k-grid-header col:nth-of-type(8),
    .k-grid-table col:nth-of-type(8) {
        width: 120px;
    }

    //Remove heading on button column
    .k-grid-header {
        .k-header:last-of-type {
            .k-cell-inner {
                display: none;
            }
        }
    }

    //CENTER CHECKBOX FOR HEADER - SUB TABLE
    .k-grid-header .k-header:first-child {
        text-align: center !important;
    }

    //DROPDOWN VISIBILITY
    .k-master-row td {
        overflow: visible !important;
    }

    .k-grid-container {
        // min-height: 400px;
    }

    // Remove vertical scrollbar
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-content {
        overflow-y: visible;
    }
}

// CENTER CHECKBOX FOR DATA - SUBSCRIPTION TABLE
.subscriber-to-single-clearance.k-grid td:first-child {
    text-align: center !important;
}

.clients-table {
    border-radius: $border-radius;

    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 50px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 50%;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 50%;
    }

    //CENTER CHECKBOX FOR HEADER - SUB TABLE
    .k-grid-header .k-header:first-child {
        text-align: center !important;
    }

    // Remove vertical scrollbar
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-content {
        overflow-y: visible;
    }

    // Default hover over header
    .k-cell-inner {
        .k-link {
            &:hover {
                cursor: default;
            }
        }
    }
}

// CENTER CHECKBOX FOR DATA - SUBSCRIPTION TABLE
.clients-table.k-grid td:first-child {
    text-align: center !important;
}

.messages-table {
    // COLUMN WIDTHS
    //-------------------
    .k-grid-header col:nth-of-type(1),
    .k-grid-table col:nth-of-type(1) {
        width: 50px;
    }
    .k-grid-header col:nth-of-type(2),
    .k-grid-table col:nth-of-type(2) {
        width: 110px;
    }
    .k-grid-header col:nth-of-type(3),
    .k-grid-table col:nth-of-type(3) {
        width: 110px;
    }
    .k-grid-header col:nth-of-type(4),
    .k-grid-table col:nth-of-type(4) {
        width: 110px;
    }
    .k-grid-header col:nth-of-type(5),
    .k-grid-table col:nth-of-type(5) {
        width: 110px;
    }
    .k-grid-header col:nth-of-type(6),
    .k-grid-table col:nth-of-type(6) {
        width: 500px;
    }
    .k-grid-header col:nth-of-type(7),
    .k-grid-table col:nth-of-type(7) {
        width: 160px;
    }
    .k-grid-header col:nth-of-type(8),
    .k-grid-table col:nth-of-type(8) {
        width: 55px;
    }

    //CENTER CHECKBOX FOR HEADER - SUB TABLE
    .k-grid-header .k-header:first-child {
        text-align: center !important;
    }

    // Remove vertical scrollbar
    .k-grid-header {
        padding: 0 !important;
    }
    .k-grid-content {
        overflow-y: visible;
    }

    // Default hover over header
    .k-cell-inner {
        .k-link {
            &:hover {
                cursor: default;
            }
        }
    }

    //Remove heading on button column
    .k-grid-header {
        .k-header:last-of-type {
            .k-cell-inner {
                display: none;
            }
        }
    }
}

// CENTER CHECKBOX FOR DATA - SUBSCRIPTION TABLE
.messages-table.k-grid td:first-child {
    text-align: center !important;
}

.row-dropdown-open {
    z-index: 6 !important;

    .k-grid-content-sticky {
        z-index: 6 !important;
    }
}
