// CUSTOM ENTRIES SORTING
.sort-by-mobile {
    display: none !important;
}

.action-sheet {
    .modal-dialog {
        width: 100% !important;
        max-width: 100% !important;
        margin: 0 !important;
        position: fixed;
        bottom: 0;
        left: 0;

        .modal-content {
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            display: block;
            // position: fixed;
            // bottom: 0;
            // left: 0;

            .modal-footer {
                padding: 1.8rem;
            }
            .modal-body {
                padding: 1.8rem 0;
                padding-bottom: 1rem;
            }

            .modal-body {
                color: $lii-text;

                h1 {
                    padding-left: 1.8rem;
                    font-size: 1rem;
                    font-weight: 500;
                    margin-bottom: 0.5rem;
                }

                // If the icon is not nested inside a sort-option-active class - don't display
                .check-icon {
                    display: none;
                }

                .sort-option-active {
                    color: $blue !important;
                    .check-icon {
                        display: block;
                        padding: 0.9rem 1.8rem;
                    }
                }

                .sort-option {
                    cursor: pointer;

                    &:focus,
                    &:hover,
                    &:active {
                        color: $blue !important;
                        background-color: $blue-100 !important;
                    }
                    p {
                        padding: 0.9rem 1.8rem;
                        font-size: 1rem;
                        font-weight: 400;
                        margin-bottom: 0;
                    }
                }
            }

            .modal-footer {
                button {
                    width: 100%;
                }
            }
        }
    }
    &.fade {
        .modal-dialog {
            transform: translate(0, 100%) !important;
        }
    }
    &.show {
        .modal-dialog {
            transform: translate(0, 0%) !important;
        }
    }
}

@media (max-width: 1139px) {
    .sort-by-mobile {
        display: block !important;
    }
}
