// stylelint-disable selector-no-qualifying-type

//
// Textual form controls
//

.form-control {
    display: block;
    width: 100%;
    height: $input-height;
    padding: $input-padding-y $input-padding-x;
    font-family: $input-font-family;
    @include font-size($input-font-size);
    font-weight: $input-font-weight;
    line-height: $input-line-height;
    color: $input-color;
    background-color: $input-bg;
    background-clip: padding-box;
    border: $input-border-width solid $input-border-color;

    // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
    @include border-radius($input-border-radius-sm, 0);

    @include box-shadow($input-box-shadow);
    @include transition($input-transition);

    // Unstyle the caret on `<select>`s in IE10+.
    &::-ms-expand {
        background-color: transparent;
        border: 0;
    }

    // Remove select outline from select box in FF
    &:-moz-focusring {
        color: transparent;
        text-shadow: 0 0 0 $input-color;
    }

    // Customize the `:focus` state to imitate native WebKit styles.
    @include form-control-focus($ignore-warning: true);

    // Placeholder
    &::placeholder {
        color: $input-placeholder-color;
        // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
        opacity: 1;
    }

    // Disabled and read-only inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled,
    &[readonly] {
        background-color: $input-disabled-bg;
        // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
        opacity: 1;
    }
}

input[type='date'],
input[type='time'],
input[type='datetime-local'],
input[type='month'] {
    &.form-control {
        appearance: none; // Fix appearance for date inputs in Safari
    }
}

select.form-control {
    &:focus::-ms-value {
        // Suppress the nested default white text on blue background highlight given to
        // the selected option text when the (still closed) <select> receives focus
        // in IE and (under certain conditions) Edge, as it looks bad and cannot be made to
        // match the appearance of the native widget.
        // See https://github.com/twbs/bootstrap/issues/19398.
        color: $input-color;
        background-color: $input-bg;
    }
}

// Make file inputs better match text inputs by forcing them to new lines.
.form-control-file,
.form-control-range {
    display: block;
    width: 100%;
}

//
// Labels
//

// For use with horizontal and inline forms, when you need the label (or legend)
// text to align with the form controls.
.col-form-label {
    padding-top: add($input-padding-y, $input-border-width);
    padding-bottom: add($input-padding-y, $input-border-width);
    margin-bottom: 0; // Override the `<label>/<legend>` default
    @include font-size(inherit); // Override the `<legend>` default
    line-height: $input-line-height;
}

.col-form-label-lg {
    padding-top: add($input-padding-y-lg, $input-border-width);
    padding-bottom: add($input-padding-y-lg, $input-border-width);
    @include font-size($input-font-size-lg);
    line-height: $input-line-height-lg;
}

.col-form-label-sm {
    padding-top: add($input-padding-y-sm, $input-border-width);
    padding-bottom: add($input-padding-y-sm, $input-border-width);
    @include font-size($input-font-size-sm);
    line-height: $input-line-height-sm;
}

.custom-control-label {
    .lead-icon {
        min-width: 1.6rem;
        margin-right: 0.6rem;
        text-align: center;
    }
}

// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
    display: block;
    width: 100%;
    padding: $input-padding-y 0;
    margin-bottom: 0; // match inputs if this class comes on inputs with default margins
    @include font-size($input-font-size);
    line-height: $input-line-height;
    color: $input-plaintext-color;
    background-color: transparent;
    border: solid transparent;
    border-width: $input-border-width 0;

    &.form-control-sm,
    &.form-control-lg {
        padding-right: 0;
        padding-left: 0;
    }
}

// Form control sizing
//
// Build on `.form-control` with modifier classes to decrease or increase the
// height and font-size of form controls.
//
// Repeated in `_input_group.scss` to avoid Sass extend issues.

.form-control-sm {
    height: $input-height-sm;
    padding: $input-padding-y-sm $input-padding-x-sm;
    @include font-size($input-font-size-sm);
    line-height: $input-line-height-sm;
    @include border-radius($input-border-radius-sm);
}

.form-control-lg {
    height: $input-height-lg;
    padding: $input-padding-y-lg $input-padding-x-lg;
    @include font-size($input-font-size-lg);
    line-height: $input-line-height-lg;
    @include border-radius($input-border-radius-lg);
}

// stylelint-disable-next-line no-duplicate-selectors
select.form-control {
    &[size],
    &[multiple] {
        height: auto;
    }
}

textarea.form-control {
    height: auto;
}

// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.

.form-group {
    margin-bottom: $form-group-margin-bottom;
}

.form-text {
    display: block;
    margin-top: $form-text-margin-top;
}

// Form grid
//
// Special replacement for our grid system's `.row` for tighter form layouts.

.form-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -$form-grid-gutter-width / 2;
    margin-left: -$form-grid-gutter-width / 2;

    > .col,
    > [class*='col-'] {
        padding-right: $form-grid-gutter-width / 2;
        padding-left: $form-grid-gutter-width / 2;
    }
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.

.form-check {
    position: relative;
    display: block;
    padding-left: $form-check-input-gutter;
}

.form-check-input {
    position: absolute;
    margin-top: $form-check-input-margin-y;
    margin-left: -$form-check-input-gutter;

    // Use [disabled] and :disabled for workaround https://github.com/twbs/bootstrap/issues/28247
    &[disabled] ~ .form-check-label,
    &:disabled ~ .form-check-label {
        color: $text-muted;
    }
}

.form-check-label {
    margin-bottom: 0; // Override default `<label>` bottom margin
}

.form-check-inline {
    display: inline-flex;
    align-items: center;
    padding-left: 0; // Override base .form-check
    margin-right: $form-check-inline-margin-x;

    // Undo .form-check-input defaults and add some `margin-right`.
    .form-check-input {
        position: static;
        margin-top: 0;
        margin-right: $form-check-inline-input-margin-x;
        margin-left: 0;
    }
}

// Form validation
//
// Provide feedback to users when form field values are valid or invalid. Works
// primarily for client-side validation via scoped `:invalid` and `:valid`
// pseudo-classes but also includes `.is-invalid` and `.is-valid` classes for
// server side validation.

@each $state, $data in $form-validation-states {
    @include form-validation-state($state, map-get($data, color), map-get($data, icon));
}

.was-validated .form-control:invalid,
.form-control.is-invalid {
    background-color: $form-feedback-invalid-bg;
}

// Inline forms
//
// Make forms appear inline(-block) by adding the `.form-inline` class. Inline
// forms begin stacked on extra small (mobile) devices and then go inline when
// viewports reach <768px.
//
// Requires wrapping inputs and labels with `.form-group` for proper display of
// default HTML form controls and our custom form controls (e.g., input groups).

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center; // Prevent shorter elements from growing to same height as others (e.g., small buttons growing to normal sized button height)

    // Because we use flex, the initial sizing of checkboxes is collapsed and
    // doesn't occupy the full-width (which is what we want for xs grid tier),
    // so we force that here.
    .form-check {
        width: 100%;
    }

    // Kick in the inline
    @include media-breakpoint-up(sm) {
        label {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 0;
        }

        // Inline-block all the things for "inline"
        .form-group {
            display: flex;
            flex: 0 0 auto;
            flex-flow: row wrap;
            align-items: center;
            margin-bottom: 0;
        }

        // Allow folks to *not* use `.form-group`
        .form-control {
            display: inline-block;
            width: auto; // Prevent labels from stacking above inputs in `.form-group`
            vertical-align: middle;
        }

        // Make static controls behave like regular ones
        .form-control-plaintext {
            display: inline-block;
        }

        .input-group,
        .custom-select {
            width: auto;
        }

        // Remove default margin on radios/checkboxes that were used for stacking, and
        // then undo the floating of radios and checkboxes to match.
        .form-check {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            padding-left: 0;
        }
        .form-check-input {
            position: relative;
            flex-shrink: 0;
            margin-top: 0;
            margin-right: $form-check-input-margin-x;
            margin-left: 0;
        }

        .custom-control {
            align-items: center;
            justify-content: center;
        }
        .custom-control-label.tag-label {
            margin-bottom: 0;
        }
    }
}

//Dropzone Area
.dropzone {
    width: 100%;
    input[type='file'] {
        display: none;
    }
    #file-drag {
        border: 1px dashed $lii-input-placeholder-text;
        border-radius: $border-radius;
        width: 100%;
        font-weight: 500;
        font-size: 1rem;
        transition: all 0.1s ease;
        .browse-word {
            cursor: pointer;
            &:hover {
                text-decoration: underline;
            }
        }
        .icon {
            font-size: 1.8rem;
            color: $lii-text-light;
        }
        &:hover {
            background-color: rgba($primary, $alpha: 0.05);
            transition: all 0.1s ease;
            border-color: $primary;
        }
    }
    &.error {
        #file-drag {
            border: 1px dashed $red-800;
            background: $red-200;
        }
    }
}

//Selected files
.selected-files {
    background: $gray-400;
    border-radius: $border-radius;
    font-size: 0.85rem;
    overflow: hidden;
    .file-information {
        flex: 0.8;
        width: 0;
        .file-name-and-size {
            a,
            div {
                flex: 0.75;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font-weight: 400;
            }
            a {
                text-decoration: underline;
            }
        }
    }
    .remove-file {
        flex: 0.2;
        cursor: pointer;
    }
    .remove-file-error-state {
        cursor: pointer;
    }
}

//Selected files - WITH dropdown
.selected-files-with-dropdown {
    background-color: #e6ebef;
    border-radius: $border-radius-xsm;
    font-size: 0.85rem;
    overflow: hidden;
    .file-information {
        // flex: 0.8;
        width: 88%;
        .file-name-and-size {
            a,
            div {
                width: 100% !important;
                flex: 0.75;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .dropdown {
        margin-left: 10px;
    }
    .remove-file {
        // flex: 0.2;
        cursor: pointer;
    }
    @media (max-width: 768px) {
        .name {
            width: 70% !important;
        }
        .size {
            width: 20% !important;
            justify-content: flex-end;
        }
        .dropdown {
            width: 100% !important;
            margin-left: 0;
        }
        .wrap-md-screen {
            display: flex;
            flex-wrap: wrap;
        }
    }
    @media (max-width: 576px) {
        .name {
            width: 55% !important;
        }
        .size {
            width: 30% !important;
        }
    }
}

//Selected files - WITHOUT dropdown
.selected-files-without-dropdown {
    background-color: #e6ebef;
    border-radius: $border-radius-xsm;
    font-size: 0.85rem;
    overflow: hidden;
    .file-information {
        flex: 0.8;
        width: 0;
        .file-name-and-size {
            a,
            div {
                flex: 0.75;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .remove-file {
        flex: 0.2;
        cursor: pointer;
    }
    .remove-file-error-state {
        cursor: pointer;
    }
}

// SUMAARY OF UPLOADS
.summary-of-uploads {
    background-color: $white;
    border-radius: 0.375rem;
    overflow: hidden;

    .summary {
        padding: 1rem 1.5rem;

        @media (max-width: 700px) {
            flex-direction: column;
        }

        .name-size-container {
            width: 55%;

            @media (max-width: 700px) {
                flex-direction: column;
                width: 100%;
            }

            .name-container {
                width: 70%;

                @media (max-width: 700px) {
                    width: 100%;
                }

                .file-name {
                    color: $blue;
                    margin-bottom: 0;
                    margin-right: 1.5rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;

                    @media (max-width: 700px) {
                        margin-right: 0;
                    }
                }
            }
            .size-container {
                @media (max-width: 700px) {
                    justify-content: flex-start !important;
                    margin-bottom: 0.5rem;
                    margin-top: 0.5rem;
                }

                .size {
                    color: $blue-dark-1000;
                }
            }
        }
        .file-type {
            width: 18%;
            margin-left: 1rem;

            @media (max-width: 700px) {
                margin-left: 0rem;
                padding-top: 0.25rem;
                width: auto;
            }
            @media (max-width: 1600px) {
                width: 25%;
            }
            @media (max-width: 1300px) {
                width: 30%;
            }
            @media (max-width: 1100px) {
                width: 36%;
            }
            @media (max-width: 1024px) {
                width: 28%;
            }
            @media (max-width: 860px) {
                width: 36%;
            }
        }
    }
}

//Super Radio Button
.super-radio {
    position: relative;
    z-index: 6;
    padding-left: 0 !important;
    cursor: pointer;
    .custom-control-label {
        cursor: pointer;
        padding: 1rem 1rem 1rem 3.65rem;
        position: relative;
        z-index: 5;
        width: 100%;
        height: 100%;
        background: $white;
        border: 1px solid $gray-400;
        border-radius: $border-radius-xsm;
        &::before,
        &::after {
            left: 1rem;
            top: 0.9rem;
            width: 1.625rem;
            height: 1.625rem;
        }
        &::after {
            background: no-repeat 55% 50% / 50% 50%;
        }
    }
    input:checked ~ label {
        border: 1px solid $primary;
        background: $blue-100;
    }
    input:checked ~ label {
        color: $primary;
    }
    .custom-control-input:checked ~ .custom-control-label::after {
        background-image: url("data:image/svg+xml,%3Csvg width='14' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M4.71 10.137.203 5.631a.693.693 0 0 1 0-.98l.98-.981c.271-.271.71-.271.981 0L5.2 6.706 11.703.203c.27-.27.71-.27.98 0l.98.98c.271.271.271.71 0 .981L5.69 10.137c-.27.271-.71.271-.98 0Z' fill='%23fff'/%3E%3C/svg%3E") !important;
        background-size: 0.9rem !important;
    }
    .custom-control-input {
        width: 100%;
        height: 100%;
        z-index: 7;
        cursor: pointer;
        top: 0;
    }
    .invalid-feedback {
        margin-top: 0.325rem !important;
    }
}

//Super radio validation
.was-validated .super-radio .custom-control-input:valid ~ .custom-control-label {
    color: $blue;
}
.was-validated .super-radio .custom-control-input:valid:checked ~ .custom-control-label::before {
    border-color: $blue;
    background-color: $blue;
}
.was-validated .super-radio .custom-control-input:valid ~ .custom-control-label::before {
    border-color: $blue;
}
.was-validated .super-radio .custom-control-input:focus ~ .custom-control-label::before {
    box-shadow: 0 0rem 0rem 0.2rem rgba($primary, 0.25);
}
.was-validated .super-radio :invalid ~ .invalid-feedback {
    display: block;
    margin-top: 1.25rem;
}
.was-validated .super-radio :invalid ~ .background-block {
    border-color: $danger;
}

//tag checkbox
.custom-control.tag-checkbox {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5rem;
    width: fit-content;
    padding: 0;
    outline: 0;

    .custom-control-label.tag-label {
        cursor: pointer;
        border-radius: 5rem;
        z-index: 7;
        margin: 0;
        display: flex;
        padding: 0.3rem 0.8rem;
        color: $lii-text;
        border-radius: 5rem;
        pointer-events: all;
        user-select: none;
        transition: all 0.3s ease;
        outline: 0;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 1px solid $gray-600;
            position: absolute;
            border-radius: 5rem;
            top: 0;
            bottom: 0;
            left: 0;
            padding: 0;
            margin: 0;
            z-index: -1;
            transition: all 0.3s ease;
            outline: 0;
        }

        &:hover {
            &:before {
                background-color: $gray-200;
            }
        }
    }

    //input
    .custom-control-input.tag-input {
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        top: 0;
        bottom: 0;
        opacity: 0;
        user-select: none;
        outline: 0;
    }
    //input focus
    .custom-control-input.tag-input:focus ~ .custom-control-label.tag-label {
        outline: 0;
    }

    .custom-control-input.tag-input ~ .custom-control-label.tag-label {
        &:after {
            display: none;
        }
    }
    //input checked
    .custom-control-input.tag-input:checked ~ .custom-control-label.tag-label {
        color: $white;
        &:before {
            background: $blue-zodiac;
            border: 1px solid $blue-zodiac;
        }
    }
}

//Calendar popup
.calendar-popup-wrapper {
    padding: 0.1rem 0.8rem;
    .calendar-popup {
        min-width: 19rem;
        th {
            padding: 0.4rem 0;
            font-weight: 700;
        }
        td {
            text-align: center;
            font-size: 0.85rem;
        }
        .month-cycle-arrow {
            color: $gray-900;
        }
        .day-name {
            padding-bottom: 0.8rem;
        }
        .day-btn {
            padding: 0;
            line-height: 1;
            font-weight: 500;
            margin: 0 auto;
            width: 1.85rem;
            height: 1.85rem;
            color: $gray-900;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            &.not-selectable {
                color: $gray-600;
                cursor: default;
            }
            &.btn-primary {
                color: white;
            }
        }
        .custom-select {
            width: auto;
            font-weight: 700;
        }
        .clear-and-today {
            text-decoration: underline;
            display: inline-block;
            margin: 0 0.4rem;
            cursor: pointer;
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
        .month-cycle-arrow,
        .day-name,
        .day-btn,
        .custom-select,
        .clear-and-today {
            font-size: 0.85rem;
        }
    }
}

@include media-breakpoint-up(md) {
    .selected-files-without-dropdown,
    .selected-files-with-dropdown {
        .file-information {
            flex: auto;
            .file-name-and-size {
                width: 100%;
                a {
                    flex: auto;
                }
                div {
                    flex: auto;
                }
            }
        }
        .remove-file {
            flex: auto;
            flex-basis: 0;
        }
    }
}

// Tag radio buttons
.custom-control.tag-radio-button {
    display: flex;
    flex-wrap: wrap;
    // flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
    border-radius: 5rem;
    width: fit-content;
    padding: 0;
    outline: 0;

    .custom-control-label.tag-label {
        cursor: pointer;
        border-radius: 5rem;
        z-index: 7;
        margin: 0;
        display: flex;
        padding: 0.3rem 0.8rem;
        color: $lii-text;
        border-radius: 5rem;
        pointer-events: all;
        user-select: none;
        transition: all 0.3s ease;
        outline: 0;

        &:before {
            content: '';
            width: 100%;
            height: 100%;
            background-color: transparent;
            border: 1px solid $gray-600;
            position: absolute;
            border-radius: 5rem;
            top: 0;
            bottom: 0;
            left: 0;
            padding: 0;
            margin: 0;
            z-index: -1;
            transition: all 0.3s ease;
            outline: 0;
        }

        &:hover {
            &:before {
                background-color: $gray-200;
            }
        }
    }

    //input
    .custom-control-input.tag-input {
        width: 100%;
        height: 100%;
        z-index: 1;
        cursor: pointer;
        top: 0;
        bottom: 0;
        opacity: 0;
        user-select: none;
        outline: 0;
    }
    //input focus
    .custom-control-input.tag-input:focus ~ .custom-control-label.tag-label {
        outline: 0;
    }
    //input checked
    .custom-control-input.tag-input:checked ~ .custom-control-label.tag-label {
        color: $white;
        &:before {
            background: $blue-zodiac;
            border: 1px solid $blue-zodiac;
        }
        &:after {
            background-image: none;
        }
    }
    .custom-control-label::after {
        content: none;
    }
}
.custom-radio.tag-radio-button .custom-control-input:checked ~ .custom-control-label::after {
    visibility: hidden;
}

// INPUT ICON SIZES
.input-icon-sm,
.input-icon-md,
.input-icon-lg {
    position: absolute;
    color: $lii-input-placeholder-text;
    right: 18px;
}

.input-icon-sm {
    font-size: 0.875rem;
    top: 35px;
}

.input-icon-md {
    font-size: 0.938rem;
    top: 37px;
}

.input-icon-lg {
    top: 41px;
}

//DATE
.date {
    position: relative;

    input {
        padding-right: 3.125rem;
    }
}

//TIME
.time {
    position: relative;

    input {
        padding-right: 3.125rem;
    }

    .dropdown-menu {
        width: 100%;
    }
}

// PHONE SELECTOR (NPM LIBRARY)
.phone-selector {
    .flag-container {
        .arrow {
            margin-left: 0.7rem;
        }

        &:hover {
            background-color: $gray-200 !important;
        }
    }

    .selected-flag {
        width: 4.375rem !important;
        border-right: 1px solid $gray-600 !important;
    }

    input {
        padding-right: 1rem !important;
        padding-left: 5.1rem !important;
        padding-top: 0.3rem !important;
        padding-bottom: 0.3rem !important;
    }

    .country-list {
        width: 100%;
    }

    .dial-code {
        color: $lii-text-light !important;
    }

    .country.highlight {
        &:hover {
            background-color: $blue-100 !important;

            .country-name {
                color: $blue;
            }
        }
    }

    .intl-tel-input .flag-container .arrow.down:after,
    .intl-tel-input .flag-container .arrow.up:after {
        font-size: 0.7rem;
        content: '' !important;
        display: inline-block;
        width: 10px;
        height: 6px;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='6' viewBox='0 0 10 6' fill='none' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M0.96875 0C0.40625 0 0.125 0.6875 0.53125 1.09375L4.53125 5.09375C4.78125 5.34375 5.1875 5.34375 5.4375 5.09375L9.4375 1.09375C9.84375 0.6875 9.5625 0 9 0H0.96875Z' fill='%23325073'/%3e%3c/svg%3e ");
    }

    .intl-tel-input .flag-container .arrow.up:after {
        transform: rotate(180deg);
    }

    .arrow {
        pointer-events: none;
    }
}

// Error message
.error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: 80%;
    color: $red;
}

.input-row-overflow {
    thead,
    tbody {
        tr {
            td, th {
                padding: 0.4rem;
                &.row-action {
                    position: sticky;
                    right: 0;
                    z-index: 5;
                    padding-left: 1.4rem;
                    &::before {
                        content: '';
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 1px;
                        height: 100%;
                        background: $hr-border-color;

                    }
                }
            }
        }
    }
    thead {
        tr{
            th {
                color: $lii-text;
                font-weight: $font-weight-medium;
                border-top: none;
                border-bottom: none;
            }
        }
    }
    tbody {
        label {
            display: none;
        }
        .phone-selector {
            margin: 0;
        }
        tr {
            td {
                border-top: none;
                border-bottom: none;
                &:last-child {
                    padding-right: 0;
                }
                input {
                    min-width: 13rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .input-row-overflow {
        thead,
        tbody {
            tr {
                td, th {
                    &.row-action {
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}
