.alerts-drawers-example {

    .alerts-drawer:first-child {
    }
}

.screen-overlay {
    height: 100%;
    z-index: 1030;
    position: fixed;
    top: 0;
    left: 0;
    opacity:0;
    visibility:hidden;
    background-color: $secondary;
    background-color: rgba($secondary, $alpha: 0.6);
    transition:opacity .2s linear, visibility .1s, width 1s ease-in;
    &.show {
        transition:opacity .5s ease, width 0s;
        opacity:1;
        width:100%;
        visibility:visible;
        }
}
.offcanvas {
    // max-width:400px;
    min-width: 100%;
    visibility: hidden;
    transform:translateX(100%);
    transition:all .2s;
    border-radius:0; 
    // box-shadow: 0 5px 10px rgba(0,0,0, .2);
    display:block;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    z-index: 1200;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;
    &.show {
        visibility: visible;
        transform: translateX(0);
        transition: transform .2s;
    }
}

.alerts-drawer {
    // flex-wrap: wrap;
    // max-width: 430px;
    // z-index: 1;
    // border-top-left-radius: $border-radius;
    // border-bottom-left-radius: $border-radius;
    // height: 100vh;
    // overflow: hidden;

    .alerts-drawer-heading {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 5;
        padding-bottom: 16px;

        .close-button {
            color: $blue-zodiac;
        }
    }
    .alert-cards {
        // height: calc(100vh - 40px);
        overflow-y: auto;
        min-width: 100%;
    }
}


.alert-card {
    border-radius: $border-radius;
    border: $border-width solid $gray-600;

    .card-content {
        position: relative;

        .alert-card-date {
            color: $lii-text;
            font-size: 13px;
            opacity: 0.6;
        }
        .alert-card-title {
            color: $blue-zodiac;
            padding-right: 85px;
            line-height:1.3;
        }
    }
}

@media (min-width: 420px)  {
    .offcanvas {
        width: 400px;
        max-width:auto;
        min-width: auto;
    }
}

