.radial-progress {
    @include size($radial-md-size, $radial-md-size);
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: inset 0 0 0 $radial-md-size / 10 $gray-200;
    transition: all 0.3s ease;

    .radial {
        @include size(100%, 100%);
        clip: rect(0, $radial-md-size, $radial-md-size, $radial-md-size / 2);
        left: 0;
        position: absolute;
        top: 0;
        transition: all 0.3s ease;

        .half-circle {
            @include size(100%, 100%);
            border: ($radial-md-size / 10) solid $primary;
            border-radius: 50%;
            clip: rect(0, $radial-md-size / 2, $radial-md-size, 0);
            left: 0;
            position: absolute;
            top: 0;
            transition: all 0.3s ease;
        }
    }

    .label {
        align-self: center;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        transition: all 0.3s ease;
    }

    &-sm {
        .label {
            font-size: 10px;
        }
    }
    &-md {
        .label {
            font-size: 14px;
        }
    }
    &-lg {
        .label {
            font-size: 16px;
        }
    }

    // sizes
    @include radial-size(sm, $radial-sm-size);
    @include radial-size(md, $radial-md-size);
    @include radial-size(lg, $radial-lg-size);
}

// colour sets
@include radials($primary, primary);
@include radials($warning, warning);
@include radials($danger, danger);
@include radials($info, info);
@include radials($success, success);
@include radials($dark, dark);
