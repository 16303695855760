.code-block-wrapper {
    &.border-bottom {
        border-color: $gray-600 !important;
    }
}

.code-block {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    max-height: 460px;
    overflow: auto;

    .btn {
        position: absolute;
        top: 1.2rem;
        right: 1rem;
    }

    pre {
        border-radius:$border-radius-sm;
        min-height: 3.8rem;
    }
}

#Containers {
    .code-block {
        margin: 0 auto;
        width: 94%;
    }
}
