// Search bar
.search-bar {
    display: block;
    

    .input-group {
        .magnifying-glass-input-icon {
            position: absolute;
            left: 16px;
            z-index: 100;
            color: $lii-input-placeholder-text;
        }
        .search-input {
            padding-left: 45px;
            // padding-right: 93px;
        }

        .advanced-search-button {
            position: absolute;
            right: 11px;
            top: -15px;
            z-index: 100;

            .btn {
                box-shadow: none;
                font-size: 16px;
            }
            .dropdown-toggle {
                &::after {
                    content: none;
                }
            }
        }
    }
    .form-clear {
        right: 12px;
        top: -7px;
    }
    &.with-advanced-search {
        .input-group {
            .magnifying-glass-input-icon {
                position: absolute;
                left: 16px;
                z-index: 100;
                color: $lii-input-placeholder-text;
            }
            .search-input {
                padding-left: 45px;
                padding-right: 93px;
            }
    
            .advanced-search-button {
                position: absolute;
                right: 11px;
                top: -15px;
                z-index: 100;
    
                .btn {
                    box-shadow: none;
                    font-size: 16px;
                }
                .dropdown-toggle {
                    &::after {
                        content: none;
                    }
                }
            }
        }
        .form-clear {
            right: 62px;
            top: -7px;
        }
    }
}

//Advanced search
.advanced-search-container .inner-wrapper,
.advanced-search-container .dropdown-menu {
    max-height: 85vh;
    overflow: hidden;
    min-width: 97vw;
}

.advanced-search-container {
    border-radius: $border-radius;
    padding: 0;

    .advanced-search-heading {
        padding: 1.3rem;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $gray-600;

        .advanced-search-title {
            margin-bottom: 0;
        }
    }
    .advanced-search-filters {
        padding: 1.3rem;
        border-bottom: 1px solid $gray-600;
        height: 100%;
        overflow: auto;

        .advanced-search-input {
            margin-bottom: 25px;

            p {
                font-size: 14px;
                margin-left: 1px;
                color: $lii-text;
                margin-top: 3px;
                margin-bottom: 0;
            }
            .form-clear {
                right: 13px;
                top: 10px;
            }
        }

        .advanced-search-mot {
            display: flex;
            align-items: flex-start;
            margin-bottom: 18px;

            form {
                min-width: 8.75rem;

                .form-check {
                    margin-bottom: 0.5rem;
                }
            }

            .custom-checkbox {
                font-size: 15px;
            }
        }

        .advanced-search-status {
            display: flex;
            align-items: center;
            margin-bottom: 18px;

            form {
                display: flex;
                align-items: center;
            }

            .custom-checkbox {
                font-size: 15px;
            }
        }

        .advanced-search-date {
            display: flex;
            align-items: flex-start;

            .dropdown-toggle {
                &::after {
                    content: '';
                }
            }
            .custom-radio {
                font-size: 15px;
                .custom-control-label {
                    font-weight: 400;
                }
            }
        }

        .filter-title {
            margin-bottom: 0;
            color: $blue-zodiac;
            font-size: 15px;
            width: 120px;
        }
    }
    .advanced-search-button-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding: 0.85rem 1.3rem;
        button {
            margin: 4px;
        }
    }
}

.search-bar,
.search-mobile,
.clearance-header-bar {
    .form-clear {
        position: absolute !important;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $lii-text;
        cursor: pointer;
        z-index: 100;
    }
}


// Mobile search bar
.search-mobile {
    background-color: $white;

    .input-group {
        padding: 1.2rem;
        border-bottom: 1px solid #eff2f4;

        .arrow-left-input-icon {
            position: absolute;
            left: 24px;
            z-index: 100;
            color: $lii-text;
        }
        .search-input {
            padding-left: 40px;
            padding-right: 83px;
        }
        .form-control.search-input {
            border: none;
            &:focus {
                box-shadow: none;
                border-color: none;
            }
        }

        .advanced-search-button {
            position: absolute;
            right: 24px;
            z-index: 100;
            font-size: 16px;

            .btn {
                box-shadow: none;
            }
            .dropdown-toggle {
                &::after {
                    content: none;
                }
            }
        }

        .form-clear {
            right: 70px;
        }
    }
    .search-button {
        padding: 1.2rem;

        button {
            width: 100%;
        }
    }
    .advanced-search-container {
        width: calc(100vw - 2.2rem);

        .advanced-search-heading {
            padding: 1.5rem;
        }
        .advanced-search-filters {
            padding: 1.2rem 1.5rem;
            height: 12rem;
            overflow: hidden;
            overflow-y: auto;

            .filter-title-mobile {
                margin-bottom: 0.8rem;
                color: $blue-zodiac;
                font-size: 15px;
                min-width: 12.5rem;
            }

            .advanced-search-mot,
            .advanced-search-status,
            .advanced-search-date {
                flex-direction: column;
                align-items: flex-start;
                margin-bottom: 15px;

                form {
                    flex-wrap: wrap;
                    min-width: 8.75rem;

                    .custom-checkbox {
                        margin-bottom: 0.7rem;
                    }
                }
            }
        }
        .advanced-search-button-container {
            padding: 1rem;
        }
    }

    .dropdown-menu {
        right: -0.3rem !important;
    }

    @media (min-width: 1024px) {
    }
}

.mobile-search-example {
    .search-mobile {
        max-width: 420px;
        .advanced-search-container {
            width: calc(100vw - 4.8rem);
        }
        .dropdown-menu {
            right: -1.5rem !important;
        }
    }
}

@media (min-width: 500px) {
    .mobile-search-example {
        .search-mobile {
            .advanced-search-container {
                width: 420px;
            }
            .dropdown-menu {
                right: -1.5rem !important;
            }
        }
    }
}


.advanced-search-button {
    @include media-breakpoint-up(lg) {
        .dropdown-menu {
            // min-width: 35rem;
        }
    }
}


@include media-breakpoint-up(sm) {
    .advanced-search-container .inner-wrapper,
    .advanced-search-container .dropdown-menu {
        min-width: 28rem;
    }
}
