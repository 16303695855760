.trust-signal {
    &.secure-data {
        color: $lii-text;
        font-size: 0.75rem;
        .lock-icon {
            color: $lii-orange;
        }
        a {
            text-decoration: underline;
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
    &.light {
        &.secure-data {
            color: $white;
            a {
                color: $light-blue;
                text-decoration: underline;
                &:hover,
                &:focus {
                    text-decoration: none;
                }
            }
        }
    }

}

.powered-by,
.powered-by path,
.powered-by::before {
        font-size: 0.9rem;
        font-weight: 500;
        color: $secondary;
        fill: $secondary;
}
.powered-by.light,
.powered-by.light path,
.powered-by.light::before {
    color:$gray-600;
    fill: $gray-600;
}


@include media-breakpoint-up(sm) {
    .trust-signal {
        &.secure-data {
            .powered-by {
                &::before {
                    content: '|';
                    margin: 0 0.45rem;
                }
            }
        }
    }
}