// stylelint-disable declaration-no-important

@each $value in $overflows {
    .overflow-#{$value} {
        overflow: $value !important;
    }
}
.off-canvas-scroll {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    white-space: nowrap;
    padding: 1.25rem;
    gap: 1rem;
}
