.toasts-wrapper {
    position: fixed;
    top: 2rem;
    left: 50%;
    transform: translateX(-50%);
    z-index: 1010;
}

.toast {
    // Prevents from shrinking in IE11, when in a flex container
    // See https://github.com/twbs/bootstrap/issues/28341
    flex-basis: $toast-max-width;
    max-width: $toast-max-width;
    @include font-size($toast-font-size);
    color: $toast-color;
    background-color: white;
    background-clip: padding-box;
    border: $toast-border-width solid $toast-border-color;
    box-shadow: $box-shadow;
    opacity: 0;
    @include border-radius($toast-border-radius);

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }

    &.showing {
        opacity: 1;
    }

    &.show {
        display: block;
        opacity: 1;
    }

    &.hide {
        display: none;
    }

    &.success {
        background-color:$success-light;
        border:1px solid $success-dark;
        .toast-body {
            .toast-icon {
                color: $success;
            }
            .toast-text {
                color:$success-dark;
            }
        }
    }
    &.warning {
        background-color:$warning-light;
        border:1px solid $warning-dark;
        .toast-body {
            .toast-icon {
                color: $warning;
            }
            .toast-text {
                color:$warning-dark;
            }
        }
    }
    &.error {
        background-color:$danger-light;
        border:1px solid $danger-dark;
        .toast-body {
            .toast-icon {
                color: $danger;
            }
        }
        .toast-text {
            color:$danger-dark;
        }
    }
}

.toast-header {
    display: flex;
    align-items: center;
    padding: $toast-padding-y $toast-padding-x;
    color: $toast-header-color;
    background-color: $toast-header-background-color;
    background-clip: padding-box;
    border-bottom: $toast-border-width solid $toast-header-border-color;
    @include border-top-radius(subtract($toast-border-radius, $toast-border-width));
}

.toast-body {
    position: relative;
    padding: $toast-padding-y $toast-padding-x;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    .toast-icon {
        margin-right: 0.75rem;
        font-size: 1.25rem;
    }
}

.alert-toasts-wrapper.toasts-wrapper {
    right: 2rem;
    left: auto;
    transform: none;

    .alert-toasts {
        max-width: 23.75rem;
        .close-button {
            position: absolute;
            top: 18px;
            right: 24px;
            color: $blue-zodiac;
        }
        .toast-title {
            color: $blue-zodiac;
            font-size: 16px;
        }
        .toast-desc {
            font-size: 15px;
        }
    }
}
