// stylelint-disable declaration-no-important

//
// Visibility utilities
//

.visible {
    visibility: visible !important;
}

.invisible {
    visibility: hidden !important;
}

.display-on-hover {
    .hide-show-element {
        visibility: visible !important;
    }

    @include media-breakpoint-up(lg) {
        .hide-show-element {
            visibility: hidden !important;
        }
        &:hover {
            .hide-show-element {
                visibility: visible !important;
            }
        }
    }
}
