.message-card-springboard {
    .message-title-and-status {
        .read-status {
            width: 0.5rem;
            height: 0.5rem;
            border-radius: 0.25rem;
            display: block;
        }
        h4 {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis; 
        }
    }
    .last-message-and-badge {
        .last-message-in-thread {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;        
        }
    }
    box-shadow: $box-shadow-sm;
    transform: scale(1) translateY(0);
    transition: all 0.3s ease;

    &.unread {
        .message-title-and-status {
            .read-status {
                background-color: $primary;
            }
        }
    }
    &.read {
        .message-title-and-status {
            .read-status {
                visibility: hidden;
                opacity: 0;
                display: none;
            }
            h4 {
                font-weight: normal;
            }
        }
        .last-message-and-badge {
            span {
                font-weight: $font-weight-normal !important;
            }
        }
    }
    &:hover,
    &:focus {
        box-shadow: $box-shadow;
        transform: scale(1.0075) translateY(-4px);
        transition: all 0.3s ease;
        cursor: pointer;
    }
    &:active {
        background-color: $gray-200;
    }
}

.share-btn {
    min-width: 5rem;
}

// *****  MESSAGE THREAD STYLES *******

// Single message
.single-message {
    background-color: $white;
    border-bottom: 1px solid $gray-400;
    &.alt {
        background-color: $gray-100;
    }
}

.message-composer {
    textarea {
        padding: 0.8rem 1rem;
    }
    .badge {
        font-size: $font-size-base;
        .remove {
            cursor: pointer;
        }
    }
}

.message-avatar {
    width: 2.2rem;
    img, svg {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@include media-breakpoint-up(md) {
    // *****  MESSAGE THREAD STYLES *******

    .message-avatar {
        width: 2.5rem;
    }
        
}

@include media-breakpoint-up(lg) {
    .message-card-springboard {
        .time-stamp {
            text-align: center;
        }
        &.read {
            .message-title-and-status {
                .read-status {
                    display: block;
                }
            }
        }
    }
 }