//width and height
@mixin size($width, $height) {
    height: $height;
    width: $width;
}
// progress indicator
@mixin draw-progress($progress, $class) {
    .radial {
        .half-circle {
            border-color: $class !important;
        }

        .left-side {
            transform: rotate($progress * 3.6deg);
        }

        @if $progress <= 50 {
            .right-side {
                display: none;
            }
        } @else {
            clip: rect(auto, auto, auto, auto);

            .right-side {
                transform: rotate(180deg);
            }
        }
    }
}
//radial size class names
@mixin radial-size($label, $size) {
    &-#{$label} {
        box-shadow: inset 0 0 0 $size / 10 $gray-600;
        @include size($size, $size);

        .radial {
            clip: rect(0, $size, $size, $size / 2);

            .half-circle {
                border: ($size / 10) solid $primary;
                clip: rect(0, $size / 2, $size, 0);
            }
        }
    }
}
//radial color class names
@mixin radials($class, $name) {
    @for $i from 1 through 100 {
        //Class name and number / value
        .radial-progress-#{$name}-#{$i} {
            @include draw-progress($i, $class);
        }
    }
}
