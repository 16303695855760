//
// Basic Bootstrap table
//

.table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.

    th,
    td {
        padding: $table-cell-padding;
        vertical-align: top;
        border-top: $table-border-width solid $table-border-color;
    }

    thead th {
        vertical-align: bottom;
        border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
        border-top: (2 * $table-border-width) solid $table-border-color;
    }
}

//
// Condensed table w/ half padding
//

.table-sm {
    th,
    td {
        padding: $table-cell-padding-sm;
    }
}

// Border versions
//
// Add or remove borders all around the table and between all the columns.

.table-bordered {
    border: $table-border-width solid $table-border-color;

    th,
    td {
        border: $table-border-width solid $table-border-color;
    }

    thead {
        th,
        td {
            border-bottom-width: 2 * $table-border-width;
        }
    }
}

.table-borderless {
    th,
    td,
    thead th,
    tbody + tbody {
        border: 0;
    }
}

// Zebra-striping
//
// Default zebra-stripe styles (alternating gray and transparent backgrounds)

.table-striped {
    tbody tr:nth-of-type(#{$table-striped-order}) {
        background-color: $table-accent-bg;
    }
}

// Hover effect
//
// Placed here since it has to come after the potential zebra striping

.table-hover {
    tbody tr {
        @include hover() {
            color: $table-hover-color;
            background-color: $table-hover-bg;
        }
    }
}

// Table backgrounds
//
// Exact selectors below required to override `.table-striped` and prevent
// inheritance to nested tables.

@each $color, $value in $theme-colors {
    @include table-row-variant($color, theme-color-level($color, $table-bg-level), theme-color-level($color, $table-border-level));
}

@include table-row-variant(active, $table-active-bg);

// Dark styles
//
// Same table markup, but inverted color scheme: dark background and light text.

// stylelint-disable-next-line no-duplicate-selectors
.table {
    .thead-dark {
        th {
            color: $table-dark-color;
            background-color: $table-dark-bg;
            border-color: $table-dark-border-color;
        }
    }

    .thead-light {
        th {
            color: $table-head-color;
            background-color: $table-head-bg;
            border-color: $table-border-color;
        }
    }
}

.table-dark {
    color: $table-dark-color;
    background-color: $table-dark-bg;

    th,
    td,
    thead th {
        border-color: $table-dark-border-color;
    }

    &.table-bordered {
        border: 0;
    }

    &.table-striped {
        tbody tr:nth-of-type(#{$table-striped-order}) {
            background-color: $table-dark-accent-bg;
        }
    }

    &.table-hover {
        tbody tr {
            @include hover() {
                color: $table-dark-hover-color;
                background-color: $table-dark-hover-bg;
            }
        }
    }
}

//Livingston Tables
.table-customs-entries {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 0.6rem;
    thead {
        display: none;
    }
    tr {
        position: relative;
        td {
            flex-direction: column;
            border-top-style: none;
            display: flex;
            justify-content: space-between;
            padding: 1rem;
            font-size: 0.813rem;
            // border-bottom: 1px solid $gray-300;
            border-left: 1px solid $gray-300;
            border-right: 1px solid $gray-300;
            position: relative;
            background-color: $white;
            z-index: 2;
            &[data-label]::before {
                content: attr(data-label);
                opacity: 0.7;
                margin-right: 1rem;
            }
            &[data-label='Clearance name'],
            &[data-label='Arrival at border'],
            &[data-label='Clearance progress'],
            &[data-label='Key milestone'],
            &[data-label='All milestones'],
            &[data-label='all-milestones-visual'] {
                width: 100%;
                border-bottom: 1px solid $gray-500;
            }
            &[data-label='Clearance name'] {
                border-radius: 8px 8px 0 0;
                border-top: 1px solid $gray-300;

                &[data-label]::before {
                    display: none;
                }
            }
            &[data-label='All milestones'] {
                border-bottom: 1px solid $gray-300;

                &[data-label]::before {
                    display: none;
                }
            }
            .badge {
                font-size: 0.875rem;
                &.disabled {
                    background: $gray-100;
                    border: 2px solid $gray-400;
                    svg {
                        opacity: 0.2;
                    }
                }
            }
            h4 {
                margin: 0;
                line-height: 1.4;
                font-size: 0.95rem;
            }
            .entry-number {
                span {
                    opacity: 0.7;
                    &:last-child {
                        opacity: 1;
                    }
                }
            }
            .last-updated {
                opacity: 0.7;
                font-size: 0.813rem;
                margin-top: 0.65rem;
            }
            .mod-icon {
                font-size: 1rem;
            }
            &.mobile-milestones-trigger {
                border-radius: 0 0 0 0;
                border-bottom-style: none;
                color: $primary;
                span {
                    opacity: 1;
                    margin-left: 0.5rem;
                    &::after {
                        content: 'Hide';
                    }
                }
                svg {
                    transform: rotate(180deg);
                    transition: all 0.25s ease;
                }
                &.collapsed {
                    border-radius: 0 0 8px 8px;
                    border-bottom: 1px solid $gray-300;

                    span {
                        &::after {
                            content: 'View more';
                        }
                    }
                    svg {
                        transform: rotate(0deg);
                        transition: all 0.25s ease;
                    }
                }
            }
            &.all-milestones-visual {
                border-radius: 0 0 8px 8px;
                border-top-style: none;
                padding: 0;
                .inner {
                    padding: 0 1rem;
                }
                .milestone-container {
                    min-width: 200px;
                }
                .milestone-text {
                    &::after {
                        content: '';
                        display: block;
                        height: 1rem;
                        width: 100%;
                        background-color: transparent;
                    }
                    span {
                        opacity: 0.7;
                    }
                }
            }
        }
        td.rush-badge-and-description {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            padding: 0;
            z-index: 5;
            border: none;
            background: transparent;
            .rush-tag {
                // transform: translate(-1rem, -1rem);
                border-top-left-radius: 7px;
                display: inline-block;
                position: relative;
                margin-right: 1.2rem;
                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 7px;
                    width: 108%;
                    height: 100%;
                    border-bottom-right-radius: 8px;
                    background-color: $lii-blue;
                    transform: skewX(163deg);
                    z-index: -1;
                }
            }
            .rush-process-time-text {
                line-height: 1.2;
                font-size: 0.7rem;
            }
        }
        &.rush-clearance {
            [data-label='Clearance name'] {
                padding-top: 3rem;
            }
            td.rush-badge-and-description {
                display: block;
            }
        }
    }
}
.custom-entries-heading {
    @media (max-width: 1139px) {
        font-size: 1rem;
    }
}

@include media-breakpoint-up(sm) {
    .table-customs-entries {
        tr {
            td.rush-badge-and-description {
                .rush-process-time-text {
                    font-size: 0.85rem;
                }
            }
        }
    }
}

@include media-breakpoint-up(xl) {
    .table-customs-entries {
        thead {
            display: contents;
            tr {
                background: transparent;
                &::before {
                    display: none;
                }
                td {
                    background: transparent;
                    border: none !important;
                    cursor: auto;
                    padding: 0.65rem 1.5rem;
                    min-height: auto !important;
                }
            }
        }
        tr {
            position: relative;
            td {
                font-size: 0.875rem;
                padding: 1.2rem 1.5rem;
                border-top: 1px solid $gray-300;
                border-left: none;
                border-right: none;
                border-bottom: none;
                &[data-label='Clearance name'] {
                    border-radius: 8px 0 0 0;
                    border-left: 1px solid $gray-300;
                    width: 35%;
                    float: left;
                    min-height: 9.5rem;
                    border-bottom: 0;
                }
                &[data-label='Arrival at border'] {
                    width: 20%;
                    float: left;
                    min-height: 9.5rem;
                    border-bottom: 0;
                }
                &[data-label='Clearance progress'] {
                    width: 20%;
                    float: left;
                    min-height: 9.5rem;
                    border-bottom: 0;
                }
                &[data-label='Key milestone'] {
                    width: 25%;
                    float: left;
                    min-height: 9.5rem;
                    border-radius: 0 8px 0 0;
                    border-right: 1px solid $gray-300;
                    border-bottom: 0;
                }
                &[data-label='All milestones'] {
                    width: 100%;
                    border-top: 1px solid $gray-300 !important;
                    border-left: 1px solid $gray-300;
                    border-right: 1px solid $gray-300;
                    padding: 0.8rem 1.5rem;
                    cursor: pointer;
                }
                &[data-label='all-milestones-visual'] {
                    width: 100%;
                    border-bottom: 1px solid $gray-300 !important;
                    border-left: 1px solid $gray-300 !important;
                    border-right: 1px solid $gray-300 !important;
                }
                &[data-label]::before {
                    display: none;
                }
                h4 {
                    font-size: 1rem;
                }
                &.mobile-milestones-trigger {
                    background-color: $white;
                    transition: all 0.2s ease;
                    border-top: 1px solid $gray-300;
                    &:hover {
                        background-color: $blue-100;
                        transition: all 0.2s ease;
                    }
                }
                &.all-milestones-visual {
                    border: 1px solid $gray-300 !important;
                    border-top: none !important;
                    .inner {
                        padding: 1rem 1.5rem 0 1.5rem;
                        .milestone-text {
                            font-size: 0.813rem;
                            &::after {
                                content: '';
                                display: block;
                                height: 1rem;
                                width: 100%;
                                background-color: transparent;
                            }
                        }
                    }
                }
            }
            td.rush-badge-and-description {
                .rush-tag {
                    margin-right: 1.5rem;
                }
                .rush-process-time-text {
                    visibility: hidden;
                    opacity: 0;
                    transform: translateX(-0.3rem);
                    transition: all 0.2s ease;
                }
            }
            &.rush-clearance {
                [data-label='Clearance name'],
                [data-label='Arrival at border'],
                [data-label='Clearance progress'],
                [data-label='Key milestone'],
                [data-label='All milestones'],
                [data-label='all-milestones-visual'] {
                    padding-top: 3.5rem;
                    border-bottom: none;
                    min-height: 11rem;
                }
                [data-label='All milestones'] {
                    min-height: 0;
                    padding-top: 0.8rem;
                }
            }
            &:hover {
                td {
                    border-color: $primary !important;
                    border-width: 1px !important;
                    // transition: border-width 0s none;
                    &[data-label='All milestones'],
                    &[data-label='all-milestones-visual'] {
                        border-color: $primary !important;
                        border-width: 1px !important;
                        border-top: 1px solid $gray-300 !important;
                        // border-left: 2px solid $primary !important;
                        // border-right: 2px solid $primary !important;
                        transition: border-width 0s linear;
                    }
                }
                .rush-badge-and-description {
                    .rush-process-time-text {
                        opacity: 1;
                        visibility: visible;
                        transform: translateX(0rem);
                        transition: all 0.2s ease;
                    }
                }
            }
        }
        .heading {
            cursor: pointer;

            .sort-icon {
                font-size: 1rem;

                &.sort-icon-default {
                    color: $lii-text-light;
                }
            }
        }
    }
}

// @media (min-width: 1360px) {
//     .table-customs-entries {
//         tr {
//             td {
//                 &:nth-child(1) {
//                     min-height: 9rem;
//                 }
//                 &:nth-child(2) {
//                     min-height: 9rem;
//                 }
//                 &:nth-child(3) {
//                     min-height: 9rem;
//                 }
//                 &:nth-child(4) {
//                     min-height: 9rem;
//                 }
//             }
//         }
//     }
// }

// Responsive tables
//
// Generate series of `.table-responsive-*` classes for configuring the screen
// size of where your table will overflow.

.table-responsive {
    @each $breakpoint in map-keys($grid-breakpoints) {
        $next: breakpoint-next($breakpoint, $grid-breakpoints);
        $infix: breakpoint-infix($next, $grid-breakpoints);

        &#{$infix} {
            @include media-breakpoint-down($breakpoint) {
                display: block;
                width: 100%;
                overflow-x: auto;
                -webkit-overflow-scrolling: touch;

                // Prevent double border on horizontal scroll due to use of `display: block;`
                > .table-bordered {
                    border: 0;
                }
            }
        }
    }
}

// NEW ROW HIGHLIGHT
.table-customs-entries {
    tbody {
        tr {
            &.new-row-highlight {
                td:not(.rush-badge-and-description):not(.all-milestones-visual) {
                    animation: new-row-highlight 5s ease;
                }
            }
        }
    }
}

@keyframes new-row-highlight {
    0% {
        border-color: $green-dark;
        background-color: $green-light;
    }
    100% {
        border-color: $gray-300;
        background-color: white;
    }
}
