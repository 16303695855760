// stylelint-disable declaration-no-important, selector-list-comma-newline-after

//
// Headings
//

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
    margin-bottom: $headings-margin-bottom;
    font-family: $headings-font-family;
    font-weight: $headings-font-weight;
    line-height: $line-height-base;
    color: $headings-color;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
    font-weight: $headings-font-weight-light;
}

h1,
.h1 {
    @include font-size($h1-font-size);
}
h2,
.h2 {
    @include font-size($h2-font-size);
}
h3,
.h3 {
    @include font-size($h3-font-size);
}
h4,
.h4 {
    @include font-size($h4-font-size);
}
h5,
.h5 {
    @include font-size($h5-font-size);
}
h6,
.h6 {
    @include font-size($h6-font-size);
}

.lead {
    @include font-size($lead-font-size);
    font-weight: $lead-font-weight;
}

// Type display classes
.display-1 {
    @include font-size($display1-size);
    font-weight: $display1-weight;
    line-height: $display-line-height;
}
.display-2 {
    @include font-size($display2-size);
    font-weight: $display2-weight;
    line-height: $display-line-height;
}
.display-3 {
    @include font-size($display3-size);
    font-weight: $display3-weight;
    line-height: $display-line-height;
}
.display-4 {
    @include font-size($display4-size);
    font-weight: $display4-weight;
    line-height: $display-line-height;
}

//
// Horizontal rules
//

hr {
    margin-top: $hr-margin-y;
    margin-bottom: $hr-margin-y;
    border: 0;
    border-top: $hr-border-width solid $hr-border-color;
    &.light {
        border-top: $hr-border-width solid $gray-900 !important;
    }
}

//
// Emphasis
//

small,
.small {
    @include font-size($small-font-size);
    font-weight: $font-weight-normal;
}

mark,
.mark {
    padding: $mark-padding;
    background-color: $mark-bg;
}

//
// Lists
//

.list-unstyled {
    @include list-unstyled();
}
.checked-list {
    list-style: none;
    padding: 0;
    li {
        font-size: 0.875rem;
        margin: 0.75rem 0;
        padding-left: 1.625rem;
        position: relative;
        &::before {
            content: '';
            position: absolute;
            top: 0.35rem;
            left: 0;
            background-repeat: no-repeat;
            background-position: 0;
            width: 1rem;
            height: 0.688rem;
        }
    }
    &-blue {
        li {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.73 10.781a.726.726 0 0 0 1.012 0l8.04-8.039a.726.726 0 0 0 0-1.012l-.985-.984a.693.693 0 0 0-.985 0L6.25 7.31 3.16 4.246a.693.693 0 0 0-.984 0l-.985.984a.726.726 0 0 0 0 1.012l4.54 4.54Z' fill='%231069C9'/%3E%3C/svg%3E");
            }
        }
    }
    &-green {
        li {
            &::before {
                background-image: url("data:image/svg+xml,%3Csvg width='15' height='11' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.73 10.781a.726.726 0 0 0 1.012 0l8.04-8.039a.726.726 0 0 0 0-1.012l-.985-.984a.693.693 0 0 0-.985 0L6.25 7.31 3.16 4.246a.693.693 0 0 0-.984 0l-.985.984a.726.726 0 0 0 0 1.012l4.54 4.54Z' fill='%230D8274'/%3E%3C/svg%3E");
            }
        }
    }
}
// Inline turns list items into inline-block
.list-inline {
    @include list-unstyled();
}
.list-inline-item {
    display: inline-block;

    &:not(:last-child) {
        margin-right: $list-inline-padding;
    }
}

//
// Misc
//

// Builds on `abbr`
.initialism {
    @include font-size(90%);
    text-transform: uppercase;
}

// Blockquotes
.blockquote {
    margin-bottom: $spacer;
    @include font-size($blockquote-font-size);
}

.blockquote-footer {
    display: block;
    @include font-size($blockquote-small-font-size);
    color: $blockquote-small-color;

    &::before {
        content: '\2014\00A0'; // em dash, nbsp
    }
}
