#FixedFooter {
    z-index: 5;
    .helper-text {
        text-align: center;
    }
}

@include media-breakpoint-up(md) {
    #FixedFooter {
        .helper-text {
            text-align: left;
        }
    }
    }

// .fixed-bar {
//     .fixed-bottom {
//         background-color: $white;
//         z-index: 100;
//         left: auto;
//         width: calc(100% - 270px);

//         @media (max-width: 1024px) {
//             width: 100%;
//         }
//         .description {
//             text-align: center;
//             color: $lii-text;

//         }
//     }
//     &.primary {
//         .fixed-bottom {
//             background-color: $primary;
//             .description {
//                 color: $white;
//             }
//         }
//     }
// }

// .side-bar-not-active {
//     .fixed-bar {
//         .fixed-bar-without-sidebar {
//             width: 100% !important;
//         }
//     }
// }


