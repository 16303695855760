.see-more-list {
    max-height: 7.6rem;
    transition: all 0.15s ease;
    overflow: hidden;

    @include media-breakpoint-up(md) {
        max-height: 100%;
    }

    &.list-expanded {
        max-height: 62.5rem;
        transition: all 0.3s ease;
    }
}

.see-more-toggle {
    color: $blue;
    text-decoration: underline;
    text-decoration-color: $blue;
    cursor: pointer;
    font-size: 0.875rem;

    @include media-breakpoint-up(md) {
        display: none;
    }
}

.collapse-caret-left {
    transform: rotate(0deg);
    transition: all 0.1s ease;
    &[aria-expanded='true'] {
        svg.fa-caret-right {
            transform: rotate(90deg);
            transition: all 0.1s ease;
        }
    }
}
