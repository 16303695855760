//
// Base styles
//

.alert {
    position: relative;
    padding: $alert-padding-y $alert-padding-x;
    margin-bottom: $alert-margin-bottom;
    border: $alert-border-width solid transparent;
    font-size: $font-size-lg;
    @include border-radius($alert-border-radius);
    a {
        text-decoration: underline;
        &:hover,
        &:focus {
            text-decoration: none;
        }
    }
}

// Headings for larger alerts
.alert-heading {
    // Specified to prevent conflicts of changing $headings-color
    color: inherit;
}

// Provide class for links that match alerts
.alert-link {
    font-weight: $alert-link-font-weight;
}

// Dismissible alerts
//
// Expand the right padding and account for the close button's positioning.

.alert-dismissible {
    padding-right: $close-font-size + $alert-padding-x * 2;

    // Adjust close link position
    .close {
        position: absolute;
        top: 0;
        right: 0;
        z-index: 2;
        padding: $alert-padding-y $alert-padding-x;
        color: inherit;
    }
}

// Alternate styles
//
// Generate contextual modifier classes for colorizing the alert.

@each $color, $value in $theme-colors {
    .alert-#{$color} {
        @include alert-variant(
            theme-color-level($color, $alert-bg-level),
            theme-color-level($color, $alert-border-level),
            theme-color-level($color, $alert-color-level)
        );
    }
}

//LIVINGSTON CUSTOM ALERT STYLES
.alert-success {
    background-color: $success-hsl-light;
    color: $success;
    border-color: rgba($success, 0.2);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $success;
    }
    a {
        color: $success;
    }
}
.alert-warning {
    background-color: $warning-hsl-light;
    color: $warning;
    border-color: rgba($warning, 0.2);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $warning;
    }
    a {
        color: $warning;
    }
}
.alert-danger {
    background-color: $danger-hsl-light;
    color: $danger;
    border-color: rgba($danger, 0.2);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $danger;
    }
    a {
        color: $danger;
    }
}
.alert-info {
    background-color: $info-hsl-light;
    color: $info;
    border-color: rgba($info, 0.2);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $info;
    }
    a {
        color: $info;
    }
}
.alert-dark {
    background-color: $dark-hsl-light;
    color: $dark;
    border-color: rgba($dark, 0.2);
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: $dark;
    }
    a {
        color: $dark;
    }
}

.alert-close-button {
    position: absolute;
    right: 23px;
    top: 19px;

    svg {
        cursor: pointer;
    }
}

.alert-success,
.alert-warning,
.alert-danger,
.alert-info,
.alert-dark {
    .dropdown-menu {
        .dropdown-item {
            color: $dropdown-link-color; 
            text-decoration: none;  
         &:hover, &:focus {
            color: $dropdown-link-hover-color;
            text-decoration: none;
         }
        }
    }
}


.btn-alert-success,
.btn-alert-warning,
.btn-alert-danger,
.btn-alert-info,
.btn-alert-dark {
    width: fit-content;
    margin-left: 2rem;
    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.2) !important;
    }
    @include media-breakpoint-up(md) {
        margin-left: 0;
    }
}

.btn-alert-success {
    border-color: $success;
    color: $success;

    &:hover,
    &:focus {
        background-color: $success !important;
        border-color: $success !important;
    }
}

.btn-alert-warning {
    border-color: $warning;
    color: $warning;

    &:hover,
    &:focus {
        background-color: $warning !important;
        border-color: $warning !important;
    }
}

.btn-alert-danger {
    border-color: $danger;
    color: $danger;

    &:hover,
    &:focus {
        background-color: $danger !important;
        border-color: $danger !important;
    }
}

.btn-alert-info {
    border-color: $info;
    color: $info;

    &:hover,
    &:focus {
        background-color: $info !important;
        border-color: $info !important;
    }
}

.btn-alert-dark {
    border-color: $dark;
    color: $dark;

    &:hover,
    &:focus {
        background-color: $dark !important;
        border-color: $dark !important;
    }
}

.alert-global {
    border: none;
    border-radius: 0;
}

.alert-icon {
    min-width: 2rem;
}

.dismiss-button-padding {
    @include media-breakpoint-up(md) {
        padding-right: 3.5rem;
    }
}

//TEXT-LINKS
.alert {
    .text-link {
        margin-left: 2rem;
        margin-top: 0.7rem;
        font-size: 0.938rem;
    }
    @include media-breakpoint-up(md) {
        .text-link {
            margin-left: 0rem;
            margin-top: 0rem;
            margin-right: 1rem;
            font-size: 0.938rem;
        }
    }
}

//GLOBAL ALERT BANNER WITH 2 CTAs
.alert-global-two-cta {
    .title {
        font-size: 1rem;
        font-weight: 500;
        margin: 0;
    }

    @include media-breakpoint-up(md) {
        .title {
            font-size: 1.125rem;
        }
    }
}

//ALERT WITH TIME AND CTA + ALERT WITH TIME AND DISMISS
.alert-with-date-and-cta,
.alert-with-date-and-dismiss {
    .text {
        @include media-breakpoint-up(md) {
            // max-width: 55%;
            width: 55%;
            // flex-grow: 4;
        }
    }

    .date,
    button,
    .text-link {
        // margin-left: 2rem;
        flex-shrink: 0;
        @include media-breakpoint-up(md) {
            // flex-grow: 1;
            // width: 20%;
        }
    }

    .date {
        margin-left: 2rem;
        @include media-breakpoint-up(md) {
            margin-left: 0;
        }
    }

    .text-link {
        margin-top: 0;
        @include media-breakpoint-up(md) {
            margin-right: 0;
        }
    }
}

.alert-with-date-and-dismiss {
    .text {
        @include media-breakpoint-up(md) {
            width: 43%;
        }
    }

    .alert-close-button {
        @include media-breakpoint-up(md) {
            position: static;
            top: 15px;
            right: 0;
        }
    }
}
