.pagination {
    display: flex;
    @include list-unstyled();
    @include border-radius();
}

.page-link {
    position: relative;
    display: block;
    padding: $pagination-padding-y $pagination-padding-x;
    margin-left: -$pagination-border-width;
    line-height: $pagination-line-height;
    color: $pagination-color;
    text-decoration: if($link-decoration == none, null, none);
    background-color: $pagination-bg;
    border: $pagination-border-width solid $pagination-border-color;

    &:hover {
        z-index: 2;
        color: $pagination-hover-color;
        text-decoration: none;
        background-color: $pagination-hover-bg;
        border-color: $pagination-hover-border-color;
    }

    &:focus {
        z-index: 3;
        outline: $pagination-focus-outline;
        box-shadow: $pagination-focus-box-shadow;
    }
}

.page-item {
    &:first-child {
        .page-link {
            margin-left: 0;
            @include border-left-radius($border-radius);
        }
    }
    &:last-child {
        .page-link {
            @include border-right-radius($border-radius);
        }
    }

    &.active .page-link {
        z-index: 3;
        color: $pagination-active-color;
        background-color: $pagination-active-bg;
        border-color: $pagination-active-border-color;
    }

    &.disabled .page-link {
        color: $pagination-disabled-color;
        pointer-events: none;
        // Opinionated: remove the "hand" cursor set previously for .page-link
        cursor: auto;
        background-color: $pagination-disabled-bg;
        border-color: $pagination-disabled-border-color;
    }
}

//
// Sizing
//

.pagination-lg {
    @include pagination-size($pagination-padding-y-lg, $pagination-padding-x-lg, $font-size-lg, $line-height-lg, $border-radius-lg);
}

.pagination-sm {
    @include pagination-size($pagination-padding-y-sm, $pagination-padding-x-sm, $font-size-sm, $line-height-sm, $border-radius-sm);
}

//DIRECT PAGINATION
.direct-pagination-desktop {
    display: flex;

    .btn-ghost {
        font-size: 0.875rem;
        font-weight: 400;

        &:hover {
            background-color: $gray-500;
        }

        &:focus {
            background-color: $gray-500 !important;
            color: $blue-zodiac-light !important;
            box-shadow: none !important;
        }
    }
    .btn-ghost-dark {
        font-size: 0.75rem;
        font-weight: 900;
        padding: 0.41rem 0.55rem;

        &:hover {
            background-color: $gray-500;
        }
        &:focus {
            background-color: $gray-500 !important;
            box-shadow: none !important;
        }
    }

    .dropdown {
        button {
            width: 4.438rem;
            text-align: left;

            &:after {
                margin-left: 1.54em;
            }
        }
        button,
        a {
            font-size: 0.875rem;
        }

        .dropdown-menu {
            padding: 0;
            min-width: 4.438rem;
            border-radius: 0.375rem;
        }

        .dropdown-item {
            padding: 0.1rem 0.8rem;
        }
    }

    .items-per-page {
        font-size: 0.938rem;
    }

    .items-on-page {
        font-size: 0.938rem;
    }
}

.show-more-mobile {
    display: none;
}

@media (max-width: 1139px) {
    .direct-pagination-desktop {
        display: none !important;
    }
    .show-more-mobile {
        display: block;
        width: 100%;
    }
}
