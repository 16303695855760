// DIRECT PROFILE PAGE
.direct-profile-page-content {
    .card-body {
        .card-title {
            font-size: 1rem;
            font-weight: 600;
            margin-bottom: 1.4rem;
        }
    }

    .password,
    .cancel-account {
        border-top: 1px solid $gray-600;
    }

    .cancel-account {
        border-top: 1px solid $gray-600;
        .desc {
            color: $lii-text;
            font-size: 0.938rem;
            margin-bottom: 1.4rem;
        }
    }

    @include media-breakpoint-up(md) {
        .card-body {
            form {
                width: 45%;
            }

            .card-title {
                font-size: 1rem;
                margin-bottom: 0;
                width: 20%;
            }
        }
        .cancel-account {
            .cancel-account-body {
                width: 45%;
                .desc {
                    font-size: 0.938rem;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}

// TEXT LINE UNDERNEATH DIRECT PROFILE PAGE
.trust-signal {
    color: $lii-text;
    font-size: 0.75rem;

    .direct-lock-icon {
        color: $lii-orange;
    }
}
