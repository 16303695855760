// Base class
//
// Kickstart any navigation component with a set of style resets. Works with
// `<nav>`s, `<ul>`s or `<ol>`s.

.nav {
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.nav-link {
    display: block;
    padding: $nav-link-padding-y $nav-link-padding-x;
    text-decoration: if($link-decoration == none, null, none);

    @include hover-focus() {
        text-decoration: none;
    }

    // Disabled state lightens text
    &.disabled {
        color: $nav-link-disabled-color;
        pointer-events: none;
        cursor: default;
    }
}

//
// Tabs
//

.nav-tabs {
    // border-bottom: $nav-tabs-border-width solid $nav-tabs-border-color;
    white-space: nowrap;
    overflow: auto;

    .nav-item {
        margin-bottom: -$nav-tabs-border-width;
    }

    .nav-link {
        font-weight: 600;
        font-size: $font-size-lg;
        color: $lii-text;
        border: $nav-tabs-border-width solid transparent;
        position: relative;
        transition: all 0.15s ease;
        &::after {
            content: '';
            width: 100%;
            height: $nav-tabs-link-active-border-width;
            background-color: $nav-tabs-link-active-border-color;
            position: absolute;
            left: 0;
            bottom: -1px;
            transform: scaleX(0);
            transition: all 0.15s ease;
        }
        // @include border-top-radius($nav-tabs-border-radius);

        @include hover-focus() {
            // border-color: $nav-tabs-link-hover-border-color;
            background-color: $blue-100;
            color: $primary;
            transition: all 0.15s ease;
            &::after {
                content: '';
                width: 100%;
                height: $nav-tabs-link-active-border-width;
                background-color: $nav-tabs-link-active-border-color;
                position: absolute;
                left: 0;
                bottom: -1px;
                transform: scaleX(1);
                transition: all 0.15s ease;
            }
        }
        &:active {
            background-color: $gray-200;
        }

        &.disabled {
            color: $nav-link-disabled-color;
            background-color: transparent;
            border-color: transparent;
        }
    }

    .nav-link.active,
    .nav-item.show .nav-link {
        color: $nav-tabs-link-active-color;
        background-color: $nav-tabs-link-active-bg;
        // border-bottom: $nav-tabs-link-active-border-width solid $nav-tabs-link-active-border-color;
        // border-color: $nav-tabs-link-active-border-color;
        // border-width: $nav-tabs-link-active-border-width;
        font-weight: 600;
        position: relative;
        transition: all 0.15s ease;
        &::after {
            content: '';
            width: 100%;
            height: $nav-tabs-link-active-border-width;
            background-color: $nav-tabs-link-active-border-color;
            position: absolute;
            left: 0;
            bottom: -1px;
            transform: scaleX(1);
            transition: all 0.15s ease;
        }
    }

    .dropdown-menu {
        // Make dropdown border overlap tab border
        margin-top: -$nav-tabs-border-width;
        // Remove the top rounded corners here since there is a hard edge above the menu
        @include border-top-radius(0);
    }
}
.nav-tabs-wrapper {
    background-color: white;
    border-bottom: 1px solid $gray-300;

    .nav {
        flex-wrap: nowrap;
    }
    .alert-badge {
        top: 12px;
        right: 0;
    }
}

//
// Pills
//

.nav-pills {
    .nav-link {
        @include border-radius($nav-pills-border-radius);
    }

    .nav-link.active,
    .show > .nav-link {
        color: $nav-pills-link-active-color;
        background-color: $nav-pills-link-active-bg;
    }
}

//
// Justified variants
//

.nav-fill {
    > .nav-link,
    .nav-item {
        flex: 1 1 auto;
        text-align: center;
    }
}

.nav-justified {
    > .nav-link,
    .nav-item {
        flex-basis: 0;
        flex-grow: 1;
        text-align: center;
    }
}

// Tabbable tabs
//
// Hide tabbable panes to start, show them when `.active`

.tab-content {
    > .tab-pane {
        display: none;
    }
    > .active {
        display: block;
    }
}
