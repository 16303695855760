//
// Base styles
//

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0; // See https://github.com/twbs/bootstrap/pull/22740#issuecomment-305868106
    height: $card-height;
    word-wrap: break-word;
    box-shadow: $card-shadow;
    background-color: $card-bg;
    background-clip: border-box;
    border: $card-border-width solid $card-border-color;
    @include border-radius($card-border-radius);

    > hr {
        margin-right: 0;
        margin-left: 0;
    }

    > .list-group {
        border-top: inherit;
        border-bottom: inherit;

        &:first-child {
            border-top-width: 0;
            @include border-top-radius($card-inner-border-radius);
        }

        &:last-child {
            border-bottom-width: 0;
            @include border-bottom-radius($card-inner-border-radius);
        }
    }

    // Due to specificity of the above selector (`.card > .list-group`), we must
    // use a child selector here to prevent double borders.
    > .card-header + .list-group,
    > .list-group + .card-footer {
        border-top: 0;
    }

    &-step {
        flex-direction: row;
        justify-content: space-between;
        padding: $spacer;

        &-text {
            display: inherit;
            align-items: center;
            &-title {
                justify-content: space-between;
            }
        }
    }
    .card-header,
    .card-body,
    .card-footer {
        @include media-breakpoint-down(md) {
            padding: 1.5rem;
        }
    }

    .image-container-centered {
        text-align: center;
        img {
            max-width: 20%;
            height: auto;
        }
    }
    .time {
        font-size: 0.875rem;
    }
}

.collapsable {
    .card-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        svg {
            transform: rotate(180deg);
            transition: all 0.3s ease;
        }
        &[aria-expanded='false'] {
            border-color: rgba(0, 0, 0, 0);
            border-radius: 0.75rem;
            transition: all 0.5s ease;
            svg {
                transform: rotate(0deg);
                transition: all 0.3s ease;
            }
        }
        &:hover,
        &:focus {
            background-color: $gray-200;
        }
    }
}

.card .shipment-detail-entry {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.4rem 0;
    &:first-child {
        padding: 0 0 0.7rem 0;
    }
    &:last-child {
        padding: 0.4rem 0 0 0;
    }
    .title {
        font-weight: 500;
        color: $secondary;
    }
}

.card .milestone-entry {
    padding: 0.5rem 0;
    .milestone-text {
        font-weight: 500;
        color: $secondary;
        .description {
            font-weight: 400;
            font-size: 0.875rem;
            color: $lii-text;
        }
    }
    .milestone-date {
        font-weight: 400;
        font-size: 0.875rem;
    }
}

.card-body {
    // Enable `flex-grow: 1` for decks and groups so that card blocks take up
    // as much space as possible, ensuring footers are aligned to the bottom.
    flex: 1 1 auto;
    // Workaround for the image size bug in IE
    // See: https://github.com/twbs/bootstrap/pull/28855
    min-height: 1px;
    padding: $card-spacer-y-lg $card-spacer-x;
    color: $card-color;
}

.card-title {
    margin-bottom: $card-spacer-y / 2;
}

.card-subtitle {
    margin-top: -$card-spacer-y / 2;
    margin-bottom: 0;
}

.card-text:last-child {
    margin-bottom: 0;
}

.card-link {
    @include hover() {
        text-decoration: none;
    }

    + .card-link {
        margin-left: $card-spacer-x;
    }
}

.card .row-highlight {
    position: relative;
    z-index: 2;
    padding: 0.6rem 0;
    &::before {
        content: '';
        background-color: $gray-200;
        width: calc(100% + 4.25rem);
        height: 100%;
        position: absolute;
        top: 0;
        left: -2.125rem;
        z-index: -1;
    }
    .badge-danger {
        font-size: 0.875rem;
    }
}

//
// Optional textual caps
//

.card-header {
    padding: $card-spacer-y $card-spacer-x;
    margin-bottom: 0; // Removes the default margin-bottom of <hN>
    color: $card-cap-color;
    background-color: $card-cap-bg;
    border-bottom: $card-border-width solid $border-color;
    transition: all 0.3s ease;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0;
        padding: 0;
    }

    &:first-child {
        @include border-radius($card-inner-border-radius $card-inner-border-radius 0 0);
    }
}

.card-footer {
    padding: $card-spacer-y $card-spacer-x;
    color: $card-cap-color;
    background-color: $card-cap-bg;
    border-top: $card-border-width solid $border-color;

    &:last-child {
        @include border-radius(0 0 $card-inner-border-radius $card-inner-border-radius);
    }
}

//
// Header navs
//

.card-header-tabs {
    margin-right: -$card-spacer-x / 2;
    margin-bottom: -$card-spacer-y;
    margin-left: -$card-spacer-x / 2;
    border-bottom: 0;
}

.card-header-pills {
    margin-right: -$card-spacer-x / 2;
    margin-left: -$card-spacer-x / 2;
}

// Card image
.card-img-overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    padding: $card-img-overlay-padding;
    @include border-radius($card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
    flex-shrink: 0; // For IE: https://github.com/twbs/bootstrap/issues/29396
    width: 100%; // Required because we use flexbox and this inherently applies align-self: stretch
}

.card-img,
.card-img-top {
    @include border-top-radius($card-inner-border-radius);
}

.card-img,
.card-img-bottom {
    @include border-bottom-radius($card-inner-border-radius);
}

// Card deck

.card-deck {
    .card {
        margin-bottom: $card-deck-margin;
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-flow: row wrap;
        margin-right: -$card-deck-margin;
        margin-left: -$card-deck-margin;

        .card {
            // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
            flex: 1 0 0%;
            margin-right: $card-deck-margin;
            margin-bottom: 0; // Override the default
            margin-left: $card-deck-margin;
        }
    }
}

//
// Pricing cards
//
.pricing-card {
    width: 18.75rem;
    min-width: 16.25rem;
    white-space: normal;
    &-popular {
        border: 3px solid $primary;
    }
}

//
// Card groups
//

.card-group {
    // The child selector allows nested `.card` within `.card-group`
    // to display properly.
    > .card {
        margin-bottom: $card-group-margin;
    }

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-flow: row wrap;
        // The child selector allows nested `.card` within `.card-group`
        // to display properly.
        > .card {
            // Flexbugs #4: https://github.com/philipwalton/flexbugs#flexbug-4
            flex: 1 0 0%;
            margin-bottom: 0;

            + .card {
                margin-left: 0;
                border-left: 0;
            }

            // Handle rounded corners
            @if $enable-rounded {
                &:not(:last-child) {
                    @include border-right-radius(0);

                    .card-img-top,
                    .card-header {
                        // stylelint-disable-next-line property-disallowed-list
                        border-top-right-radius: 0;
                    }
                    .card-img-bottom,
                    .card-footer {
                        // stylelint-disable-next-line property-disallowed-list
                        border-bottom-right-radius: 0;
                    }
                }

                &:not(:first-child) {
                    @include border-left-radius(0);

                    .card-img-top,
                    .card-header {
                        // stylelint-disable-next-line property-disallowed-list
                        border-top-left-radius: 0;
                    }
                    .card-img-bottom,
                    .card-footer {
                        // stylelint-disable-next-line property-disallowed-list
                        border-bottom-left-radius: 0;
                    }
                }
            }
        }
    }
}

//
// Columns
//

.card-columns {
    .card {
        margin-bottom: $card-columns-margin;
    }

    @include media-breakpoint-up(sm) {
        column-count: $card-columns-count;
        column-gap: $card-columns-gap;
        orphans: 1;
        widows: 1;

        .card {
            display: inline-block; // Don't let them vertically span multiple columns
            width: 100%; // Don't let their width change
        }
    }
}

//
// Accordion
//
.card {
    &.accordion {
        overflow-anchor: none;
        h3 {
            padding: 1.5rem 1.2rem;
            margin: 0;
            border-bottom: 1px solid $gray-400;
        }

        .accordion-item {
            border-bottom: 1px solid $gray-200;
            &-trigger,
            &-content {
                padding: 1rem 1.5rem;
            }
            &-trigger {
                cursor: pointer;

                &:not(.collapsed) {
                    background-color: $blue-100;
                }
                &:hover {
                    background-color: $blue-100;
                }
                &-chevron {
                    transform: rotate(180deg);
                }
                &.collapsed {
                    .accordion-item-trigger-chevron {
                        transform: rotate(0deg);
                    }
                }
            }
            &-content {
                color: $lii-text;
            }
        }
        .accordion-item:last-child {
            border-bottom: none;
        }

        > .card {
            overflow: hidden;

            &:not(:last-of-type) {
                border-bottom: 0;
                @include border-bottom-radius(0);
            }

            &:not(:first-of-type) {
                @include border-top-radius(0);
            }

            > .card-header {
                @include border-radius(0);
                margin-bottom: -$card-border-width;
            }
        }
    }
}
//LIVINGSTON CUSTOM CARDS
.card-step {
    .btn {
        margin-top: 0.1rem;
    }
}

@include media-breakpoint-up(lg) {
    .card {
        &.accordion {
            h3 {
                padding: 2.5rem 1.8rem;
            }
            .accordion-item {
                &-trigger,
                &-content {
                    padding: 1.5rem 2.125rem;
                }
            }
        }
    }
    .card .shipment-detail-entry {
        flex-direction: row;
        .title {
            margin-right: 1.4rem;
            width: 13.75rem;
        }
    }
}

// STANDARD CARD WITH BUTTON RIGHT
.card {
    .standard-with-button-right {
        .button-container {
            @include media-breakpoint-up(md) {
                white-space: nowrap;
            }
        }
    }
}

// PAYMENT METHOD CARD
.payment-method-card {
    .card-header {
        h4 {
            font-size: 1rem;
        }
    }
    .card-title {
        font-size: 1rem;
    }
    .account-detail {
        font-size: 0.875rem;
        font-weight: 400;
    }

    .account-note {
        color: $lii-text-light;
        font-size: 0.875rem;
    }

    @include media-breakpoint-up(md) {
        .card-header {
            h4 {
                font-size: 1.25rem;
            }
        }
        .account-note,
        .account-detail {
            font-size: 1rem;
        }
    }
}

// NEW-CLEARANCE
.new-clearance {
    .steps {
        font-size: 0.875rem;
        color: $blue-zodiac;
        font-weight: 500;

        span {
            color: $lii-text;
        }
    }
    .form-check {
        padding-bottom: 1.8rem;
        border-bottom: 1px solid $border-color;

        .radio-heading {
            font-weight: 600;
        }
    }

    .note {
        color: $lii-text-light;
    }
}

// CARD WITH IMAGE AND BADGE
.card-with-image-and-badge {
    .card-img-wrapper {
        height: 150px;
        width: 100%;

        @include media-breakpoint-up(md) {
            height: auto;
            width: 190px;
            max-width: 190px;
            min-width: 190px;
        }

        .card-img {
            position: relative;
            background-color: $cyan-light;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border-bottom-right-radius: 0;
            border-bottom-left-radius: 0;

            @include media-breakpoint-up(md) {
                border-bottom-left-radius: $border-radius;
                border-bottom-right-radius: 0;
                border-top-right-radius: 0;
            }

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                min-width: 100%;
                min-height: 100%;
                // width: 310px;
                // height: 310px;
                -webkit-transform: translate3d(-50%, -50%, 0);
                transform: translate3d(-50%, -50%, 0);
                object-position: bottom;
            }
        }
    }
    .time {
        white-space: nowrap;
    }
}

// PRODUCT LIST CARD
.product-list-item {
    position: relative;
    &.selected {
        border-color: $primary !important;
        background: $blue-100 !important;
    }
    .product-info-review {
        font-size: 0.875rem;
    }
    .row-header {
        svg.fa-caret-right {
            transform: rotate(0deg);
            transition: all 0.1s ease;
        }
    }

    &[aria-expanded='true'] {
        border-color: $primary !important;
        border-width: 2px !important;
        .row-header {
            svg.fa-caret-right {
                transform: rotate(90deg);
                transition: all 0.1s ease;
            }
        }
    }
    &.for-review {
        cursor: pointer;
    }
}

.card-with-list {
    .text {
        color: $blue-zodiac;
        font-size: 0.875rem;
    }
    .card-header {
        @include media-breakpoint-up(md) {
            .title {
                font-size: 1.5rem;
            }
        }
    }
    .card-body {
        .badge {
            font-size: 0.875rem;
        }
        .glossary {
            font-size: 0.875rem;
            color: $lii-text;

            @include media-breakpoint-up(md) {
                margin-left: 2.75rem;
            }
        }
    }
}

@include media-breakpoint-up(md) {
    // PRODUCT LIST CARD
    .product-list-item {
        .action-buttons {
            position: absolute;
            top: 50%;
            right: 1.2rem;
            padding: 0;
            transform: translateY(-50%);
            flex: none;
            max-width: 6rem;
            // background-color: lighten($blue-100, 2%);
            background: rgb(243, 249, 254);
            background: linear-gradient(90deg, rgba(243, 249, 254, 0) 0%, rgba(243, 249, 254, 1) 8%);
            visibility: hidden;
        }
        &:hover,
        &:focus {
            background-color: lighten($blue-100, 2%);
            .action-buttons {
                visibility: visible;
            }
        }
        &.selected {
            &:hover,
            &:focus {
                background: rgb(238, 248, 252);
            }
            .action-buttons {
                background: rgb(238, 248, 252);
                background: linear-gradient(90deg, rgba(238, 248, 252, 0) 0%, rgba(238, 248, 252, 1) 8%);
            }
        }
    }
}

// CLEARANCE DETAIL CARD - SUBMITTED DOCUMENTS
.clearance-detail-submitted-documents {
    .card-body {
        font-size: 0.875rem;
        max-height: 360px;
        overflow: auto;
        .submitted-documents-timeline {
            position: relative;
            overflow: hidden;
            &::before {
                content: '';
                width: 1px;
                height: 100%;
                background-color: $lii-text-light;
                position: absolute;
                top: 1rem;
                left: 0.405rem;
            }
            .submitted-document {
                padding-left: 1.5rem;
                position: relative;
                &::before {
                    content: '';
                    width: 0.9rem;
                    height: 0.9rem;
                    background-color: $lii-text-light;
                    border-radius: 8px;
                    position: absolute;
                    top: 0.8rem;
                    left: 0;
                    border: 2px solid white;
                }
                &.new {
                    .inner {
                        background-color: $success-hsl-light;
                        border: 1px solid;
                        border-color: rgba($success, 0.2);
                    }
                }
            }
        }
    }
}

// Add new document highlight
.submitted-document {
    .new-document-highlight {
        animation: new-document-highlight 5s ease;
    }
}
@keyframes new-document-highlight {
    0% {
        border: 1px solid;
        border-color: rgba($success, 0.2);
        background-color: $green-light;
    }
    100% {
        border: 1px solid;
        border-color: $white;
        background-color: white;
    }
}
