.custom-toggle {
    * {
        --switch-height: 18px;
        --switch-padding: 6px;
        --switch-width: calc((var(--switch-height) * 2) - var(--switch-padding));
        --slider-height: calc(var(--switch-height) - var(--switch-padding));
        --slider-on: calc(var(--switch-height) - var(--switch-padding));
    }

    .switch {
        position: relative;
        display: inline-block;
        width: var(--switch-width);
        height: var(--switch-height);
    }

    .switch input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: $lii-text-light;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    .slider:before {
        content: '';
        position: absolute;
        height: var(--slider-height);
        width: var(--slider-height);
        left: calc(var(--switch-padding) / 2);
        bottom: calc(var(--switch-padding) / 2);
        background-color: white;
        -webkit-transition: 0.4s;
        transition: 0.4s;
    }

    input:checked + .slider {
        background-color: $blue-light;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px $blue-light;
    }

    input:checked + .slider:before {
        transform: translateX(var(--slider-on));
    }

    .slider.round {
        border-radius: var(--slider-height);
    }

    .slider.round:before {
        border-radius: 50%;
    }
    input:disabled ~ .slider {
        opacity: 0.25;
        cursor:not-allowed;
    }
}
