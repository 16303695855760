.breadcrumb-wrapper {
    background-color: $breadcrumb-bg;
    // border-bottom: $card-border-width solid $gray-400;
}
.breadcrumb {
    display: flex;
    flex-wrap: nowrap;
    padding: $breadcrumb-padding-y-lg 0;
    // padding: $breadcrumb-padding-y $breadcrumb-padding-x;
    // margin-bottom: $breadcrumb-margin-bottom;
    @include font-size($breadcrumb-font-size);
    list-style: none;
    background-color: $breadcrumb-bg;
    // @include border-radius($breadcrumb-border-radius);
    margin: 0;
    // border-bottom: $card-border-width solid $gray-200;
    width: 100%;
    overflow: auto;
}

.breadcrumb-item {
    display: flex;
    white-space: nowrap;
    a {
        font-weight: 500;
        text-decoration: underline;
        white-space: nowrap;
    }

    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .breadcrumb-item {
        padding-left: $breadcrumb-item-padding;

        &::before {
            background-image: url("data:image/svg+xml,%3Csvg width='6' height='9' viewBox='0 0 6 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M5.46191 4.83301C5.70801 4.58691 5.70801 4.17676 5.46191 3.90332L1.74316 0.18457C1.46973 -0.0615234 1.05957 -0.0615234 0.813477 0.18457L0.18457 0.813477C-0.0615234 1.08691 -0.0615234 1.49707 0.18457 1.74316L2.83691 4.39551L0.18457 7.02051C-0.0615234 7.2666 -0.0615234 7.67676 0.18457 7.9502L0.813477 8.55176C1.05957 8.8252 1.46973 8.8252 1.74316 8.55176L5.46191 4.83301Z' fill='%2399B2BE'/%3E%3C/svg%3E%0A");
            content: '' !important;
            display: inline-block; // Suppress underlining of the separator in modern browsers
            padding-right: $breadcrumb-item-padding;
            width: 6px;
            height: 9px;
            background-repeat: no-repeat;
            position: relative;
            top: 7px;
            right: 2px;
        }
    }

    // IE9-11 hack to properly handle hyperlink underlines for breadcrumbs built
    // without `<ul>`s. The `::before` pseudo-element generates an element
    // *within* the .breadcrumb-item and thereby inherits the `text-decoration`.
    //
    // To trick IE into suppressing the underline, we give the pseudo-element an
    // underline and then immediately remove it.
    + .breadcrumb-item:hover::before {
        text-decoration: underline;
    }
    // stylelint-disable-next-line no-duplicate-selectors
    + .breadcrumb-item:hover::before {
        text-decoration: none;
    }

    &.active {
        color: $breadcrumb-active-color;
    }
}

// @include media-breakpoint-up(md) {
//     .breadcrumb {
//         padding: $breadcrumb-padding-y-lg 0;
//     }
// }
