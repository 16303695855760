// stylelint-disable selector-no-qualifying-type

//
// Base styles
//

.btn {
    display: inline-block;
    font-family: $btn-font-family;
    font-weight: $btn-font-weight;
    color: $body-color;
    text-align: center;
    text-decoration: if($link-decoration == none, null, none);
    white-space: $btn-white-space;
    vertical-align: middle;
    user-select: none;
    background-color: transparent;
    border: $btn-border-width solid transparent;
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-line-height, $btn-border-radius-sm);
    @include transition($btn-transition);

    @include hover() {
        color: $body-color;
        text-decoration: none;
    }

    &:focus,
    &.focus {
        outline: 0;
        box-shadow: $btn-focus-box-shadow;
    }

    // Disabled comes first so active can properly restyle
    &.disabled,
    &:disabled {
        opacity: $btn-disabled-opacity;
        @include box-shadow(none);
    }

    &:not(:disabled):not(.disabled) {
        cursor: if($enable-pointer-cursor-for-buttons, pointer, null);

        &:active,
        &.active {
            @include box-shadow($btn-active-box-shadow);

            &:focus {
                @include box-shadow($btn-focus-box-shadow, $btn-active-box-shadow);
            }
        }
    }
}

// Future-proof disabling of clicks on `<a>` elements
a.btn.disabled,
fieldset:disabled a.btn {
    pointer-events: none;
}

//
// Alternate buttons
//
//example
@each $color, $value in $theme-colors {
    .btn-#{$color} {
        @include button-variant($value, $value);
    }
}

//cta button
.btn-cta {
    @include button-variant($warning, $warning);
}



@each $color, $value in $theme-colors {
    .btn-outline-#{$color} {
        @include button-outline-variant($value);
    }
}

//
// Link buttons
//

// Make a button look and behave like a link
.btn-link {
    font-weight: $font-weight-bold;
    color: $link-color;
    text-decoration: $link-decoration;

    @include hover() {
        color: $link-hover-color;
        background-color: $blue-200;
        // text-decoration: $link-hover-decoration;
    }

    &:disabled,
    &.disabled {
        color: $btn-link-disabled-color;
        pointer-events: none;
    }

    // No need for an active state here
}

//
// Button Sizes
//

.btn-lg {
    @include button-size($btn-padding-y-lg, $btn-padding-x-lg, $btn-font-size-lg, $btn-line-height-lg, $btn-border-radius-lg);
}

.btn-sm {
    @include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-line-height-sm, $btn-border-radius-sm);
}

//
// Block button
//

.btn-block {
    display: block;
    width: 100%;

    // Vertically space out multiple block buttons
    + .btn-block {
        margin-top: $btn-block-spacing-y;
    }
}

// Specificity overrides
input[type='submit'],
input[type='reset'],
input[type='button'] {
    &.btn-block {
        width: 100%;
    }
}

//LIVINGSTON NEW BUTTONS AND OVERRIDES
.btn-secondary {
    border: $btn-border-width solid $primary;
    background-color: transparent;
    color: $primary;
    @include hover() {
        border: $btn-border-width solid $primary;
        background-color: $primary;
        color: $white;
    }
    &:focus,
    &.focus {
        background-color: darken($primary, $amount: 5) !important;
        border: $btn-border-width solid $primary !important;
        box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, 0.25) !important;
    }
    &.disabled,
    &:disabled {
        color: $primary;
        background-color: transparent;
        border: $btn-border-width solid $primary;
    }
}
.btn-tertiary {
    border: $btn-border-width solid $gray-600;
    // background-color: transparent;
    color: $lii-text;
    @include hover() {
        // border: $btn-border-width solid $primary;
        background-color: $gray-200;
        color: $lii-text;
    }
    &:focus,
    &.focus {
        background-color: darken(rgba($gray-200, 1), $amount: 5) !important;
        border: $btn-border-width solid $gray-600 !important;
        box-shadow: 0 0 0 $input-btn-focus-width rgba(rgba($gray-200, 1), 1) !important;
        color: $lii-text !important;
    }
    &.disabled,
    &:disabled {
        color: $lii-text;
        background-color: transparent;
        border: $btn-border-width solid $gray-600;;
    }
}
.btn-tertiary-blue {
    border: $btn-border-width solid $gray-600;
    background-color: $white;
    color: $primary;
    @include hover() {
        background-color: $gray-200;
        color: $primary;
    }
    &:focus,
    &.focus {
        background-color: darken(rgba($gray-200, 1), $amount: 5) !important;
        border: $btn-border-width solid $gray-600 !important;
        box-shadow: 0 0 0 $input-btn-focus-width rgba(rgba($gray-200, 1), 1) !important;
        color: $primary !important;
    }
    &.disabled,
    &:disabled {
        color: $primary;
        background-color: transparent;
        border: $btn-border-width solid $gray-600;;
    }
}
.btn-tertiary-white {
    border: $btn-border-width solid $white;
    border-color: rgba($white, $alpha: 0.25);
    background-color: transparent;
    color: $white;
    @include hover() {
        background-color: rgba($white, $alpha: 0.05);
        border-color: rgba($white, $alpha: 0.25);
    }
    &:focus,
    &.focus {
        background-color: rgba($white, $alpha: 0.1) !important;
        box-shadow: 0 0 0 $input-btn-focus-width rgba(rgba($white, 0.08), 0.25) !important;
    }
    &.disabled,
    &:disabled {
        color: $white;
        background-color: transparent;
        border-color: rgba($white, $alpha: 0.25);
    }
}
.btn-ghost {
    border: 1px solid transparent;
    background-color: transparent;
    color: $primary;
    @include hover() {
        background-color: rgba($primary, 0.08);
        border: 1px solid rgba($primary, 0.03);
        color: $primary;
    }
    &:focus,
    &.focus {
        background-color: rgba($primary, 0.15) !important;
        border: 1px solid rgba($primary, 0.03);
        box-shadow: 0 0 0 $input-btn-focus-width rgba($primary, 0.2) !important;
        color: $primary !important;
    }
    &.disabled,
    &:disabled {
        color: $primary;
        background-color: transparent;
    }
}
.btn-ghost-dark {
    border: 1px solid transparent;
    background-color: transparent;
    color: $lii-text;
    @include hover() {
        background-color: rgba($lii-text, 0.08);
        border: 1px solid rgba($lii-text, 0.03);
        color: $lii-text;
    }
    &:focus,
    &.focus {
        background-color: rgba($lii-text, 0.15) !important;
        border: 1px solid rgba($lii-text, 0.03);
        box-shadow: 0 0 0 $input-btn-focus-width rgba($lii-text, 0.2) !important;
        color: $lii-text !important;
    }
    &.disabled,
    &:disabled {
        color: $lii-text;
        background-color: transparent;
    }
}
.btn-ghost-danger {
    border: 1px solid transparent;
    background-color: transparent;
    color: $danger;
    @include hover() {
        background-color: rgba($danger, 0.08);
        border: 1px solid rgba($danger, 0.03);
        color: $danger;
    }
    &:focus,
    &.focus {
        background-color: rgba($danger, 0.15) !important;
        border: 1px solid rgba($danger, 0.03);
        box-shadow: 0 0 0 $input-btn-focus-width rgba($danger, 0.2) !important;
        color: $danger !important;
    }
    &.disabled,
    &:disabled {
        color: $danger;
        background-color: transparent;
    }
}


