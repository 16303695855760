// Base class
//
// Requires one of the contextual, color modifier classes for `color` and
// `background-color`.

.badge {
    display: inline-block;
    padding: $badge-padding-y $badge-padding-x;
    @include font-size($badge-font-size);
    font-weight: $badge-font-weight;
    line-height: 1.2;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    @include border-radius($badge-border-radius);
    @include transition($badge-transition);

    &-unfilled {
        background: transparent !important;
        border: 2px solid $gray-400;
        text-indent: -1000em;
    }

    &-circular {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        font-size: 16px;
        vertical-align: middle !important;
        // line-height: 2;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        flex-shrink: 0;

        &-xs {
            width: 18px;
            height: 18px;
            font-size: 10px;
            // line-height: 1.2;
        }
        &-sm {
            width: 28px;
            height: 28px;
            font-size: 16px;
            // line-height: auto;
        }
        &-lg {
            width: 50px;
            height: 50px;
            font-size: 20px;
            // line-height: 1.8;
        }
        &-xl {
            width: 60px;
            height: 60px;
            font-size: 24px;
            // line-height: 1.9;
        }
    }

    @at-root a#{&} {
        @include hover-focus() {
            text-decoration: none;
        }
    }

    // Empty badges collapse automatically
    &:empty {
        display: none;
    }
}

// Quick fix for badges in buttons
.btn .badge {
    position: relative;
    top: -1px;
}

// Pill badges
//
// Make them extra rounded with a modifier to replace v3's badges.

.badge-pill {
    padding-right: $badge-pill-padding-x;
    padding-left: $badge-pill-padding-x;
    @include border-radius($badge-pill-border-radius);
}

// Colors
//
// Contextual variations (linked badges get darker on :hover).

@each $color, $value in $theme-colors {
    .badge-#{$color} {
        @include badge-variant($value);
    }
}

//LIVINGSTON CUSTOM ALERT STYLES
.badge-success {
    background-color: $success-hsl-light;
    color: $success;
}
.badge-warning {
    background-color: $warning-hsl-light;
    color: $warning;
}
.badge-danger {
    background-color: $danger-hsl-light;
    color: $danger;
}
.badge-info {
    background-color: $info-hsl-light;
    color: $info;
}
.badge-neutral {
    background-color: $dark-hsl-light;
    color: $lii-text;
}

//ALERT BADGE
.alert-badge {
    position: absolute;
    display: inline-block;
    border-radius: 0.625rem;
    background-color: $red;
    color: $white;
    font-size: 0.688rem;
    text-align: center;
    min-width: 1.125rem;
    font-weight: 600;
    padding-left: 0.375rem;
    padding-right: 0.375rem;
    padding-top: 0.063rem;
    padding-bottom: 0.063rem;
}